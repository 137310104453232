import { Injectable } from '@angular/core';
import {HttpHeaders,HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map} from 'rxjs/operators'
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EmailService {
apiUrl=environment.baseUrl;
  public emailApi:string =`${this.apiUrl}/utility/getEmailTemplates`;
  public updateApi:string = `${this.apiUrl}/utility/updateEmailTemplateDetails`;
  public getemailIdApi:string = `${this.apiUrl}/utility/getEmailTemplatesById`;
  public smsApi:string =`${this.apiUrl}/utility/getSmsTemplates`;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))

  };



  constructor(private _http:HttpClient) { }

  private extractData(res:Response): Array <any> | {}{
    const body=res;
    console.log('res',res)
    return body || {}



  }

public getEmailTemplatesList(pId:string):Observable<any>{
  return this._http.get(`${this.emailApi}`+'?pId=' + pId,this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getEmailId(pId:string,emailTemplateId:any):Observable<any>{
  return this._http.get(`${this.getemailIdApi}`+'?pId='+pId+'&emailTemplateId='+emailTemplateId,this.httpOptions).pipe(
    map(this.extractData)
  )
}

 public updateEmailList(pId:string,emailTemplateId:any,record:any):Observable<any>{
   return this._http.put(`${this.updateApi}`+'?pId='+pId+'&emailTemplateId='+emailTemplateId,record,this.httpOptions).pipe(
     map(this.extractData)
   )
 }


 public getSmsTemplatesList(pId:string):Observable<any>{
  return this._http.get(`${this.smsApi}`+'?pId=' + pId,this.httpOptions).pipe(
    map(this.extractData)
  )
}


}
