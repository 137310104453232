import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable } from 'rxjs';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {
 public static customerFlag: string;

  
  apiUrll=environment.baseUrl;

  public apiUrl : string = `${this.apiUrll}/user/login`;
  public apiUrl1: string = `${this.apiUrll}/user/getUserByUserNameAndPassword`;
  public apiUrl2: string = `${this.apiUrll}/user/getAgentById`;
  public userById: string = `${this.apiUrll}/user/getUserDetailsByUserId`;
  public custUrl:string=`${this.apiUrl}/customer/getCustomerByUserId`;
  public getUserWalletDetailsByUserIdUrl:string=`${this.apiUrll}/user/getUserWalletDetailsByUserId`;
  public getUserWalletDetailsByUserIdForIndex:string=`${this.apiUrll}/user/getUserWalletDetailsByUserIdInDisendingOrder`;

  public getUserWalletDetailsByUserIdAndCreatedOnUrl:string=`${this.apiUrll}/user/getUserWalletDetailsByUserIdAndCreatedOn`;

public getUserWalletDetailsByUserIdAndBetweenDatesUrl:string=`${this.apiUrll}/user/getUserWalletDetailsByUserIdAndBetweenDates`;

public updateUserWalletDetailsByUserIdUrl:string=`${this.apiUrll}/user/updateUserWalletDetailsByUserId`;
public getUserWalletDetailsByUserWalletIdUrl:string=`${this.apiUrll}/user/getUserWalletDetailsByUserWalletId`;
public getUserWalletDetailsByUserIdAndBwDates:string=`${this.apiUrll}/user/getUserWalletDetailsByUserIdBwDates`;
public deactivateAccountUrl:string=`${this.apiUrll}/user/deactivateUser`;
  httpOptions = {
        headers : new HttpHeaders ({'content-type' : 'application/json'})
  };
  
  

    // constructor(private _http : HttpClient) { }
    constructor(private _http:HttpClient,private _httpp:HttpClient,
      handler: HttpBackend) {
        this._httpp = new HttpClient(handler);
      }
    private  extractData( res: Response ) : Array <any> | {} {
      const body = res;
      console.log('res ',res)
      return body || {} ;
    }
    public postUser(userRecord:any,pId : string): Observable <any>{
  console.log("userRecord12333...!"+userRecord.userName+", "+userRecord.password);
  // return this._http.post(`${this.apiUrl}`+'?userName='+`${userRecord.userName}`+'&password='+`${userRecord.password}`,
  // this.httpOptions)
  return this._httpp.post(`${this.apiUrl}`,userRecord,this.httpOptions)

}


    public getUser(userRecord:any,pId : string): Observable <any>{
      console.log("userRecord...!"+userRecord.userName+", "+userRecord.password);
      return this._http.get(`${this.apiUrl1}`+'?pId='+pId+'&userName='+`${userRecord.userName}`+'&password='+`${userRecord.password}`,this.httpOptions).pipe(
      map(this.extractData)
      );
    }

    public getUserById(userId:any,pId : string): Observable <any>{
      console.log("userId...!"+userId);
      return this._http.get(`${this.apiUrl2}`+'?pId='+pId+'&Id='+userId,this.httpOptions).pipe(
      map(this.extractData)
      );
    }

    public getUserDetailsById(userId:any,pId : string): Observable <any>{
      console.log("userId...!"+userId);
      return this._httpp.get(`${this.userById}`+'?pId='+pId+'&Id='+userId,this.httpOptions).pipe(
      map(this.extractData)
      );
    }
    public getCustomerByUserId(userId:any,pId: string): Observable<any> {
      console.log("getCustomerByUserId..." + userId);
      return this._http.get(`${this.custUrl}` + '?pId=' + pId+'&userId='+userId).pipe(
        map(this.extractData)
      );
    }


    //getUserWalletDetailsByUserIdUrl

    public getUserWalletDetailsByUserId(userId:any,pId: string): Observable <any>{
        console.log("userId...!"+userId);
        return this._httpp.get(`${this.getUserWalletDetailsByUserIdUrl}`+'?pId='+pId+'&userId='+userId).pipe(
        map(this.extractData)
        );
      }

      //getUserWalletDetailsByUserIdUrl

      public getUserWalletDetailsByUserIdIndex(userId:any,pId: string): Observable <any>{
          console.log("userId...!"+userId);
          return this._http.get(`${this.getUserWalletDetailsByUserIdForIndex}`+'?pId='+pId+'&userId='+userId).pipe(
          map(this.extractData)
          );
        }

    //updateUserWalletDetailsByUserIdUrl
    public updateUserWalletDetailsByUserId(record:any,userId:any,pId : string){
      return this._http.put(`${this.updateUserWalletDetailsByUserIdUrl}`+'?pId='+pId+'&userId='+userId,record,this.httpOptions).pipe(
          map(this.extractData)
      )
    }
    //getUserWalletDetailsByUserIdAndBetweenDatesUrl
    public getUserWalletDetailsByUserIdAndCreatedOn(userId:any,fromDate:any,toDate:any,pId: string): Observable <any>{
        console.log("userId...!"+userId);
        return this._http.get(`${this.getUserWalletDetailsByUserIdAndCreatedOnUrl}`+'?pId='+pId+'&userId='+userId+'&fromDate='+fromDate+'&toDate='+toDate).pipe(
        map(this.extractData)
        );
      }
    //getUserWalletDetailsByUserIdAndBetweenDatesUrl
    public getUserWalletDetailsByUserIdAndBetweenDates(userId:any,days:any,pId: string): Observable <any>{
        console.log("userId...!"+userId+"days..!"+days);
        return this._http.get(`${this.getUserWalletDetailsByUserIdAndBetweenDatesUrl}`+'?pId='+pId+'&userId='+userId+'&days='+days).pipe(
        map(this.extractData)
        );
      }
    //getUserWalletDetailsByUserWalletIdUrl
    public getUserWalletDetailsByUserWalletId(userWalletId:any,pId: string): Observable <any>{
        console.log("userId...!"+userWalletId);
        return this._http.get(`${this.getUserWalletDetailsByUserWalletIdUrl}`+'?pId='+pId+'&userWalletId='+userWalletId).pipe(
        map(this.extractData)
        );
      }
      //getUserWalletDetailsByUserWalletIdUrl
      public getUserWalletDetailsByUserIdBwDates(pId:any,userId: any,fromDate:any,toDate: any): Observable <any>{
          console.log("userId...!"+userId);
          console.log("fromDate...!"+fromDate);
          console.log("toDate...!"+toDate);
          return this._http.get(`${this.getUserWalletDetailsByUserIdAndBwDates}`+'?pId='+pId+'&userId='+userId+'&fromDate='+fromDate+' 00:00:00'+'&toDate='+toDate+' 23:59:59').pipe(
          map(this.extractData)
          );
        }

        public deactivateAccount(userId:any,status:any,pId: string): Observable<any> {
          console.log("deactivateAccount..." + userId);
          return this._http.put(`${this.deactivateAccountUrl}` + '?pId=' + pId+'&userId='+userId+'&status='+status,this.httpOptions).pipe(
            map(this.extractData)
          );
        }

        getIpAdress(){
          return "https://api.ipify.org/?format=json";
        }

}
