import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AgentMappingService {
  apiUrl=environment.baseUrl;
  public srcAgentsUrl: string = `${this.apiUrl}/user/getAgentsListByFunctionality`;

  public destAgentsUrl: string = `${this.apiUrl}/user/getDestAgentsListByFunctionalityAndAgentId`;

  public saveMappingUrl: string = `${this.apiUrl}/agent/saveAgentMapping`;
public getApiNamesUrl:string=`${this.apiUrl}/utility/getAllApiNamesList`;
public updateExchRateApiNameByIdUrl:string=`${this.apiUrl}/exchRate/updateExchRateApiNameById`;
public retriveAgentBenifPaymentTypeListUrl: string = `${this.apiUrl}/agent/retriveAgentBenifPaymentTypeList`;
public updateBenifPaymentTypeStatusUrl:string=`${this.apiUrl}/agent/updateBenifPaymentTypeStatus`;
public updateApiNameByIdUrl:string=`${this.apiUrl}/transaction/updateApiInfoById`;
  public getAllMappingCountriesUrl:string=`${this.apiUrl}/agent/getAllMappingCountries`;
  public updateMappingStatusUrl:string=`${this.apiUrl}/agent/updateMappingStatus`


  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))

  };
  httpOptions1 = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })

  };
  constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute,private _httpp:HttpClient,
    handler: HttpBackend) {
      this._httpp = new HttpClient(handler);
    }


  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res ', res)
    return body || {};
  }
  public getAllMappingCountries(pId:string): Observable<any> {
      console.log('mapping countries....',pId);
      return this._http.get(this.getAllMappingCountriesUrl + '?pId=' + pId, this.httpOptions).pipe(
        map(this.extractData)
      );
    }
    public updateApiNameById(pId:string,id:any,val:any): Observable<any> {
      console.log('updateApiNameById....',pId);
      return this._http.put(this.updateApiNameByIdUrl + '?pId=' + pId+'&apiInfoId='+id+'&apiName='+val, this.httpOptions).pipe(
        map(this.extractData)
      );
    }

  public updateExchRateApiNameById(pId:string,id:any,val:any): Observable<any> {
    console.log('updateExchRateApiNameById....exchangerateid.'+id+' , '+val);
    alert(id)
    alert(val)
    return this._http.put(this.updateExchRateApiNameByIdUrl + '?pId=' + pId+'&exchRateId='+id+'&apiName='+val,this.httpOptions1).pipe(
      map(this.extractData)
    );
  }

  //retriveAgentBenifPaymentTypeListUrl
  public retriveAgentBenifPaymentTypeList(pId:string): Observable<any> {
  console.log('mapping retriveAgentBenifPaymentTypeListUrl....',pId);
  return this._http.get(this.retriveAgentBenifPaymentTypeListUrl + '?pId=' + pId).pipe(
  map(this.extractData)
  );
  }
  public getAllApiNamesList(pId:string): Observable<any> {
    console.log('getAllApiNames....',pId);
    return this._http.get(this.getApiNamesUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getSrcAgentsList(pId: string,data:any): Observable<any> {
    console.log("srcAgentsUrl  list.." + pId);
    return this._httpp.post(`${this.srcAgentsUrl}`+'?pId='+ pId,data, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getDestAgentsList(functionality: string,agentId:any,pId: string): Observable<any> {
    console.log("destAgentsUrl  list.." + pId);
    return this._httpp.get(`${this.destAgentsUrl}` + '?pId=' + pId+'&functionality='+functionality+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public createAgentMapping(mappingRecord: any,pId:string): Observable<any> {
    console.log('mappingRecord....',pId);
    return this._http.post(this.saveMappingUrl + '?pId=' + pId, mappingRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  //updateBenifPaymentTypeStatusUrl
  public updateBenifPaymentTypeStatus(pId:string,id:any,status:any): Observable<any> {
  console.log('updateBenifPaymentTypeStatusUrl....',pId);
  return this._http.get(this.updateBenifPaymentTypeStatusUrl + '?pId=' + pId+'&id='+id+'&status='+status).pipe(
  map(this.extractData)
  );
  }

  //updateMappingStatusUrl
public updateMappingStatus(pId:string,id:any,status:any): Observable<any> {
console.log('updateMappingStatusUrl....',pId);
return this._http.get(this.updateMappingStatusUrl + '?pId=' + pId+'&id='+id+'&status='+status).pipe(
map(this.extractData)
);
}

}
