import { Injectable } from '@angular/core';
import {HttpBackend, HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import {Observable} from 'rxjs';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  getBankValidationForTerraPay(arg0: string, value: any) {
    throw new Error('Method not implemented.');
  }
    apiUrl=environment.baseUrl;
    public createTopUpUrl:string=`${this.apiUrl}/reloadly/createTopUp`
    public updateStatusByAirTxnIdUrl:string=`${this.apiUrl}/reloadly/updateStatusByAirTxnId`;
    public getAllAirTransactionsURL:string=`${this.apiUrl}/reloadly/getAllAirTxn`
    public updateStatusByBillTxnIdUrl:string=`${this.apiUrl}/reloadly/updateStatusByBillTxnId`;
    public getAllBIllTransactionsURL:string=`${this.apiUrl}/reloadly/getAllBillTxn`
    public createBillUrl:string=`${this.apiUrl}/reloadly/createBillPayment`
    public reloadlyTransactionDetailsByCustomerIdUrl:string=`${this.apiUrl}/reloadly/getAllReloadlyTransactionByPaymentType`;
    public getBillersByCountryIsoUrl:string=`${this.apiUrl}/reloadly/getBillers`
    public saveTopUpDetailss:string=`${this.apiUrl}/reloadly/saveTopupDetails`;
    public getTopUpPaymentDetails:string=`${this.apiUrl}/trustPayment/saveReloadlyPaymentRequest`;
    public getAllOperatorsByPhoneAndCountryIsoUrl:string=`${this.apiUrl}/reloadly/getAllOperatorsByPhoneAndCountryIso`
    public getCountriesFromReloadlyUrl:string=`${this.apiUrl}/reloadly/getCountries`
    public getRejectedListByRejectedDateUrl:string=`${this.apiUrl}/utility/getRejectedListByRejectedDate`;
    public getComplainceListByApprovedDateUrl:string=`${this.apiUrl}/utility/getComplainceListByApprovedDate`;
    public txnListByStatusAndUserIdAndDateUrl:string=`${this.apiUrl}/transaction/getTransactionListDetailsBySendingCustomerUserIdAndDateAndStatus`
    public uploadAdditionalDocumentsUrl: string=`${this.apiUrl}/transaction/addAdditionalDocuments`;
    public sentTransactionsToComplianceUrl : string=`${this.apiUrl}/transaction/sentTransactionsToCompliance`;
    public getAdditionalDocumentsUrl: string=`${this.apiUrl}/transaction/getComplianceDocumentsByTransactionId`;
  public sendEmailToUploadDocumentsUrl : string=`${this.apiUrl}/transaction/sendMailToUploadDocuments`;
 public rejectTransactionByComplainceUrl:string=`${this.apiUrl}/transaction/rejectTransactionByComplaince`;
 public sentTransactionsToUnComplianceUrl : string=`${this.apiUrl}/transaction/sentTransactionsToUnCompliance`;
  public sentTxnListUrl:string=`${this.apiUrl}/transaction/getSentTransactionListByCreatedDate`;
  public exchangerateUrl : string=`${this.apiUrl}/exchRate/getExchangeRatesByDestAndSrcAgentIdAndPType`;
  public serviceFeeUrl:string=`${this.apiUrl}/transaction/getServiceFeeBySrcAndDestAgentAndPtype`;
  public collectionFeeUrl:string=`${this.apiUrl}/transaction/getColtnFeeByAgentColtnIdAndSendAmount`;
  public sendingAgentsUrl:string=`${this.apiUrl}/user/getFrontendSrcAgentsByFunctionality`;
  public recAgentsUrl:string=`${this.apiUrl}/user/getFrontendDestAgentsByFunctionality`;
  public collectiontypeUrl: string = `${this.apiUrl}/agent/getAgentCollectionList`;
  public paymentTypeUrl: string = `${this.apiUrl}/agent/getAgentBenifPaymentTypeList`;
  public trustPaymentUrl:string=`${this.apiUrl}/trustPayment/savePaymentRequest`
  public volumePaymentUrl:string=`${this.apiUrl}/volumepay/saveVolumePaymentRequest`
  
  public getTransactionDocumentsUrl:string=`${this.apiUrl}/transaction/getImage`
  public getTransactionsProfitListByCreatedDateUrl:string=`${this.apiUrl}/transaction/getTransactionsProfitListByCreatedDate`

  public createTxnUrl: string=`${this.apiUrl}/transaction/createTransactionWithNewCustomer`;
  public addressProofTypesUrl:string=`${this.apiUrl}/utility/getAddressProofTypes`;
  public identityTypesUrl:string=`${this.apiUrl}/utility/getIdentityTypes`;
  public txnListUrl:string=`${this.apiUrl}/transaction/getTransactionsList`;
  public createTxnUrl1: string=`${this.apiUrl}/transaction/createTransactionWithExistCustomer`;
  public createTxnWithIdProofUrl: string=`${this.apiUrl}/transaction/createTransactionWithNewCustomerWithIdProofs`;
public getAllApiNamesUrl:string=`${this.apiUrl}/transaction/getAllApiNamesAndCountries`
  public txnUrl: string=`${this.apiUrl}/transaction/getTransactionById`;
  public saveBankDetails: string=`${this.apiUrl}/transaction/saveBankInfo`;
  public getBankList: string=`${this.apiUrl}/transaction/getBanksList`;
  public getCashCollPointList: string=`${this.apiUrl}/transaction/getCashCollPointList`;
  public saveCshCollDetail: string=`${this.apiUrl}/transaction/saveCashCollInfo`;
  public cashColtnByTxnIdUrl: string=`${this.apiUrl}/transaction/getCashCollectionByTransactionId`;
  public bankDepositByTxnIdUrl: string=`${this.apiUrl}/transaction/getBankDepositTransactionId`;
  public cashColtnByRecAgentIdUrl: string=`${this.apiUrl}/transaction/getCashCollPointList`;
  public bankDepositByRecCountryIdUrl: string=`${this.apiUrl}/transaction/getBanksListByCountryId`;


  public customerByIdUrl:string=`${this.apiUrl}/customer/getCustomerByUserId`;
  public txnListByStatusUrl: string=`${this.apiUrl}/transaction/getTransactionsListByStatusAndCreatedDate`;
  public txnListBySrcAgentIdAndStatusUrl: string=`${this.apiUrl}/transaction/getTransactionsListBySrcAgentAndStatus`;
  public cancelTxnListUrl: string=`${this.apiUrl}/transaction/getCancelTransactionList`;
  public refundTxnUrl: string=`${this.apiUrl}/transaction/getRefundTransactionList`;
  public cancelTransactionUrl:string=`${this.apiUrl}/transaction/cancelTransactionByTransactionId`;
  public getcustomerlistApi:string=`${this.apiUrl}/customer/getCustomersListByAgentId`;
 
  public getCustomerListbyFieldsApi:string=`${this.apiUrl}/customer/getCustomerListBySearchFileds`
  public getBeneficiaryListUrl:string=`${this.apiUrl}/customer/getBeneficiaryListByCustomerIdAndPaymentType`;
  public getTransactionListByStatusUrl : string=`${this.apiUrl}/transaction/getTransactionListBasedOnStatus`;

  public getTransactionListByStatusAndAgentIdUrl : string=`${this.apiUrl}/transaction/getTransactionListBasedOnStatusAndRecAgentId`;

  public updateStatusByTxnIdUrl:string=`${this.apiUrl}/transaction/updatePaymentStatusByTxnId`;
  public refundTransactionUrl:string=`${this.apiUrl}/transaction/refundTransactionByTransactionId`;

  public txnListByStatusAndKycUrl: string=`${this.apiUrl}/transaction/getTransactionsListByStatusAndCreatedDateAndKycStatus`;
  public txnListByStatusAndKycs: string=`${this.apiUrl}/transaction/getTransactionsListByStatusAndCreatedDateAndKyc`;
  public pendingtxnListUrl: string=`${this.apiUrl}/transaction/getpendingTransactionsByCreatedDate`;

  public pendingtxnListBySendingAgentUrl: string=`${this.apiUrl}/transaction/getpendingTransactionsByCreatedDateAndSendingAgent`;

  public cancelTxnListBySendingAgentUrl: string=`${this.apiUrl}/transaction/getCancelTxnListBySendingAgentANdCreatedDate`;

  public refundTxnsBySendingAgentUrl: string=`${this.apiUrl}/transaction/getRefundTransactionListBySendingAgentIdAndCreatedDate`;

  public txnListByStatusAndKycANdSendingAgentUrl: string=`${this.apiUrl}/transaction/getTransactionListByStatusAndKycStatusAndSendingAgent`;

  public txnListByStatusAndKycANdRecAgentUrl: string=`${this.apiUrl}/transaction/getTransactionListByStatusAndKycStatusAndRecAgent`;

  public duplicateCustomerUrl: string =`${this.apiUrl}/customer/findDuplicateCustomer`;

  public bankDepositsByBenifId=`${this.apiUrl}/transaction/getBankDepositByBenficiaryId`;
  public cashCollsByBenifId :string = `${this.apiUrl}/transaction/getCashCollPointByBenficiaryId`;

  public duplicateBeneficiaryUrl=`${this.apiUrl}/customer/findDuplicateBenificiary`;
public duplicateCustomerUrl1 :string = `${this.apiUrl}/customer/findDuplicateCustomerByEmailId`;
public getVelocitytByPeriodTypeAndSrcCountryUrl:string=`${this.apiUrl}/utility/getVelocitytByPeriodTypeAndSrcCountry`;
public getSendingAmountByCustomerIdUrl:string=`${this.apiUrl}/transaction/getSendingAmountByCustomerId`;
public getApiInfoByExchRateIdUrl=`${this.apiUrl}/utility/getApiInfoByExchRateId`;

public getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeUrl : string=`${this.apiUrl}/exchRate/getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType`;

public getAgentCollectionListByAgentIdAndCurrencyUrl: string = `${this.apiUrl}/agent/getAgentCollectionListByAgentIdAndCurrency`;

public getAgentBenifPaymentTypeListByAgentIdAndCurrencyUrl: string = `${this.apiUrl}/agent/getAgentBenifPaymentTypeListByAgentIdAndCurrency`;


public getServiceFeeBySrcAndDestAgentAndPtypeAndCurrencyUrl:string=`${this.apiUrl}/transaction/getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency`;
public getIdentityTypesBasedOnCountryUrl:string=`${this.apiUrl}/utility/getIdentityTypesListBySrcAgentId`;
public getSendAgentShowListUrl:string=`${this.apiUrl}/user/getSendAgentShowList`;
public getAllTransactionProfitListUrl:string=`${this.apiUrl}/transaction/getTransactionProfitList`;
public saveComplainceUrl:string=`${this.apiUrl}/utility/saveComplaince`;
public insertIdentityTypeUrl:string=`${this.apiUrl}/utility/saveIdentityTypes`;
public getTrxnListByCompilanceStatusAndCreatedDateUrl:string=`${this.apiUrl}/transaction/getTrxnListByCompilanceStatusAndCreatedDate`;


//getBankDepositById
public getBankDepositByIdUrl:string=`${this.apiUrl}/transaction/getBankDepositById`;
//getCashCollectionById
public getCashCollectionByIdUrl:string=`${this.apiUrl}/transaction/getCashCollectionById`;
//getBeneficiaryByCustomerId
public getBeneficiaryByCustomerIdUrl:string=`${this.apiUrl}/transaction/getBeneficiaryByCustomerId`;
public getIdentityTypesBySrcAgent:string=`${this.apiUrl}/utility/getIdentityTypesListBySrcAgentId`;
//getColtnFeeByAgentIdAndSendAmount
public getColtnFeeByAgentIdAndSendAmountUrl:string=`${this.apiUrl}/transaction/getColtnFeeByAgentIdAndSendAmount`;

public getBenifPayTypes: string=`${this.apiUrl}/agent/getAgentBenifPaymentTypeListByAgentId`;
//1
public saveNotesToTransactionUrl:string=`${this.apiUrl}/transaction/addNotesToTransaction`;
//2
public getNotesListByTransactionIdUrl:string=`${this.apiUrl}/transaction/getNotesListOfTransaction`;
//3
public updateTransactionStatusUrl:string=`${this.apiUrl}/transaction/updateTransactionStatus`;
public transactionPaymentWithIdProofUrl:string=`${this.apiUrl}/transaction/transactionPaymentWithIdProof`;
public retrieveTransactionListByCreatedDateUrl:string=`${this.apiUrl}/transaction/retrieveTransactionListByCreatedDate`;
public getCountryByCountryName=`${this.apiUrl}/transaction/getCountryByCountryName`;
public getVolumeTransactionStatusUrl=`${this.apiUrl}/volumepay/getVolumePaymentStatus`;
//4
  // httpOptions = {
  //   headers : new HttpHeaders ({}).set('Authorization', localStorage.getItem("datas"))
  //
  // }
   httpOptions = {
     headers : new HttpHeaders ({})

   };

   constructor(private _http:HttpClient,private _httpp:HttpClient,
    handler: HttpBackend) {
      this._httpp = new HttpClient(handler);
    }
  private extractData( res :Response ) : Array <any> | {} {
    const body = res;
    console.log('res',res);
    return body || {};
  }
  public updateTransactionStatus(txnId:any,pId: string): Observable<any> {
    console.log("working txnId.." + txnId);
    return this._http.get(`${this.updateTransactionStatusUrl}` + '?pId=' + pId+'&transactionId='+txnId).pipe(
      map(this.extractData)
    );
  }
  public updateTransactionStatuss(txnId:any,status:any,reason:any,pId: string): Observable<any> {
  console.log("working txnId.." + txnId);
  return this._http.get(`${this.updateTransactionStatusUrl}` + '?pId=' + pId+'&transactionId='+txnId+'&status='+status+'&reason='+reason).pipe(
    map(this.extractData)
  );
}

  public saveNotesToTransaction(txnId:any,notes:any,pId: string): Observable<any> {
    console.log("working pId.." + pId);
    return this._http.get(`${this.saveNotesToTransactionUrl}` + '?pId=' + pId+'&transactionId='+txnId+'&notes='+notes)
  }

  public sentTransactionsToUnCompliance(pId:any,record:any): Observable<any> {
    console.log('sentTransactionsToUnCompliance....', pId);
    return this._http.post(`${this.sentTransactionsToUnComplianceUrl}`+'?pId=' + pId,record, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public saveTrustPayment(record:any,pId: string): Observable<any> {
    console.log('trustPayment....', pId);
    return this._http.post(`${this.trustPaymentUrl}`+'?pId=' + pId,record, this.httpOptions)
  }

  public saveVolumePayment(record:any,pId: string): Observable<any> {
    console.log('volumepayment....', pId);
    return this._http.post(`${this.volumePaymentUrl}`+'?pId=' + pId,record, this.httpOptions)
  }

  public agentenifPayTypesByRecAgentId(Id:any, pId: string): Observable<any> {
      console.log('cashColtnByRecAgentIdUrl....', Id);
      return this._http.get(`${this.getBenifPayTypes}`+'?pId=' + pId+'&agentId='+Id).pipe(
        map(this.extractData)
      )
    }

  //getTrxnListByCompilanceStatusAndCreatedDateUrl
  public getIdentityTypeBySrcAgent(pId:any,srcAgentId:string) : Observable <any>{
    return this._http.get(`${this.getIdentityTypesBySrcAgent}`+'?pId='+pId+'&srcAgentId='+srcAgentId,this.httpOptions).pipe(
      map(this.extractData)
    )

  }

  public getTransactionListByStatus(pId:any,status:any): Observable<any> {
    console.log('getTransactionListByStatusUrl....', pId);
    return this._http.get(`${this.getTransactionListByStatusUrl}`+'?pId=' + pId+'&status='+status, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  

  public getTransactionListByStatusAndAgentId(pId:any,status:any,agentId:any): Observable<any> {
    console.log('getTransactionListByStatusUrl....', pId);
    return this._http.get(`${this.getTransactionListByStatusAndAgentIdUrl}`+'?pId=' + pId+'&status='+status+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //getTrxnListByCompilanceStatusAndCreatedDateUrl
  public getTrxnListByCompilanceStatusAndCreatedDate(complainceRequired:any,fromDate:any,toDate:any,pId:string) : Observable <any>{
  console.log('getTrxnListByCompilanceStatusAndCreatedDate====',pId)
    return this._http.get(`${this.getTrxnListByCompilanceStatusAndCreatedDateUrl}`+'?pId='+pId+'&complainceRequired='+complainceRequired+'&fromDate='+fromDate+'&toDate='+toDate,this.httpOptions).pipe(
      map(this.extractData)
    )

  }

  
public sendEmailToUploadDocuments(transactionId:any,pId: string): Observable<any> {
  return this._http.get(`${this.sendEmailToUploadDocumentsUrl}`+'?pId=' + pId+'&transactionId='+transactionId, this.httpOptions).pipe(
    map(this.extractData)
  )
}
  public getNotesListOfTransaction(txnId:any,pId: string): Observable<any> {
    console.log("working txnId.." + txnId);
    return this._http.get(`${this.getNotesListByTransactionIdUrl}` + '?pId=' + pId+'&transactionId='+txnId).pipe(
      map(this.extractData)
    );
  }


  public getAdditionalDocuments(transactionId:any,pId: string): Observable<any> {
    return this._httpp.get(`${this.getAdditionalDocumentsUrl}`+'?pId=' + pId+'&transactionId='+transactionId).pipe(
      map(this.extractData)
    )
  }
  //saveComplainceUrl
  public saveComplaince(pId: string,record:any): Observable<any> {
    console.log('saveComplainceUrl....', record);
    return this._http.post(`${this.saveComplainceUrl}`+'?pId=' + pId,record, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //getApiInfoByExchRateIdUrl
  public getApiInfoByExchRateId(exchangeRateId:any,pId:any) : Observable <any>{
  console.log('getApiInfoByExchRateIdUrl====',exchangeRateId)
    return this._httpp.get(`${this.getApiInfoByExchRateIdUrl}`+'?pId='+pId+'&exchangeRateId='+exchangeRateId,this.httpOptions).pipe(
      map(this.extractData)
    )

  }

  public cancelTransactionByComplaince(transactionId:any,cancelReason:any,cancelBy:any,pId: string): Observable<any> {
    console.log('cancelTransaction....', pId);
    return this._http.get(`${this.rejectTransactionByComplainceUrl}`+'?pId=' + pId+'&transactionId='+transactionId+'&cancelReason='+cancelReason+'&cancelBy='+cancelBy, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  //getSendingAmountByCustomerIdUrl
  public getSendingAmountByCustomerId(sendingCustomerUserId:any,days:any,pId:string) : Observable <any>{
  console.log('getSendingAmountByCustomerIdUrl====',pId)
    return this._http.get(`${this.getSendingAmountByCustomerIdUrl}`+'?pId='+pId+'&sendingCustomerUserId='+sendingCustomerUserId+'&days='+days,this.httpOptions).pipe(
      map(this.extractData)
    )

  }

  //getVelocitytByPeriodTypeAndSrcCountryUrl
  public getVelocitytByPeriodTypeAndSrcCountry(periodType:any,srcCountry:any,pId:string) : Observable <any>{
  console.log('getVelocitytByPeriodTypeAndSrcCountryUrl====',pId)
    return this._http.get(`${this.getVelocitytByPeriodTypeAndSrcCountryUrl}`+'?pId='+pId+'&periodType='+periodType+'&srcCountry='+srcCountry,this.httpOptions).pipe(
      map(this.extractData)
    )

  }


  //sentTxnListUrl
    public sentTxnList(pId:string,apistatus:string,fromDate:any,toDate:any) : Observable <any>{
    console.log('sentTxnListUrl====',apistatus)
      return this._http.get(`${this.sentTxnListUrl}`+'?pId='+pId+'&apiStatus='+apistatus+'&fromDate='+fromDate+'&toDate='+toDate,this.httpOptions).pipe(
        map(this.extractData)
      )
    }

  public cashColtnByBenifId(Id:any, pId: string): Observable<any> {
      return this._http.get(`${this.cashCollsByBenifId}`+'?pId=' + pId+'&benficiaryId='+Id).pipe(
        map(this.extractData)
      )
    }
  public bankDepositByBenifId(Id:any, pId: string): Observable<any> {
      return this._http.get(`${this.bankDepositsByBenifId}`+'?pId=' + pId+'&benficiaryId='+Id, this.httpOptions).pipe(
        map(this.extractData)
      )
    }
//getBankDepositByIdUrl
public getBankDepositById(Id:any, pId: string): Observable<any> {
    return this._http.get(`${this.getBankDepositByIdUrl}`+'?pId=' + pId+'&id='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
//getCashCollectionByIdUrl
public getCashCollectionById(Id:any, pId: string): Observable<any> {
    return this._http.get(`${this.getCashCollectionByIdUrl}`+'?pId=' + pId+'&id='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
//getBeneficiaryByCustomerIdUrl
public getBeneficiaryByCustomerId(Id:any, pId: string): Observable<any> {
    return this._http.get(`${this.getBeneficiaryByCustomerIdUrl}`+'?pId=' + pId+'&customerId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public cashColtnByRecAgentId(Id:any, pId: string): Observable<any> {
    console.log('cashColtnByRecAgentIdUrl....', pId);
    return this._http.get(`${this.cashColtnByRecAgentIdUrl}`+'?pId=' + pId+'&userId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public bankDepositByRecCountryId(Id:any, pId: string): Observable<any> {
    console.log('bankDepositByRecCountryIdUrl....', pId);
    return this._http.get(`${this.bankDepositByRecCountryIdUrl}`+'?pId=' + pId+'&countryId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public cashColtnByTxnId(Id:any, pId: string): Observable<any> {
    console.log('cashColtnByTxnIdUrl....TransactionId..', Id);
    return this._http.get(`${this.cashColtnByTxnIdUrl}`+'?pId=' + pId+'&transactionId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public bankDepositByTxnId(Id:any, pId: string): Observable<any> {
    console.log('bankDepositByTxnIdUrl....', pId);
    return this._http.get(`${this.bankDepositByTxnIdUrl}`+'?pId=' + pId+'&transactionId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public transactionPaymentWithIdProof(Id:any, pId: string,data:any): Observable<any> {
    console.log('transactionPaymentWithIdProofUrl....', pId);
    return this._http.post(`${this.transactionPaymentWithIdProofUrl}`+'?pId=' + pId+'&transactionId='+Id, data, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  getBeneficiaryListByCustomerId(customerId: any, paymentType: any, pId: string): Observable<any> {
    const params = new HttpParams()
      .set('pId', pId)
      .set('customerId', customerId);
  
    return this._http.get(`${this.getBeneficiaryByCustomerIdUrl}`, { params: params, ...this.httpOptions })
      .pipe(
        map(this.extractData),
        catchError(error => {
          console.error('Error occurred while fetching beneficiary list:', error);
          return throwError(error);
        })
      );
  }

public getCustomerById(userId:any,pId: string): Observable<any> {
  console.log('getCustomerById....', pId);
  return this._http.get(`${this.customerByIdUrl}`+'?pId=' + pId+'&userId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}




public getUserById(userId:any,pId: string): Observable<any> {
  console.log('getCustomerById....', pId);
  return this._http.get(`${this.customerByIdUrl}`+'?pId=' + pId+'&userId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}





public getTxnById(transactionId:any,pId: string): Observable<any> {
  console.log('getTxnById....', pId);
  return this._http.get(`${this.txnUrl}`+'?pId=' + pId+'&transactionId='+transactionId, this.httpOptions).pipe(
    map(this.extractData)
  )
}

  public getTxnList( pId: string): Observable<any> {
    console.log('getTxnList....', pId);
    return this._http.get(`${this.txnListUrl}`+'?pId=' + pId, this.httpOptions).pipe(
map(this.extractData)
    )

  }

  public getAddressProofTypes( pId: string): Observable<any> {
    console.log('getAddressProofTypes....', pId);
    return this._http.get(`${this.addressProofTypesUrl}`+'?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getIdentityTypes( pId: string): Observable<any> {
    console.log('getIdentityTypes....', pId);
    return this._http.get(`${this.identityTypesUrl}`+'?pId=' + pId).pipe(
      map(this.extractData)
    )
  }
  public txnListByStatusAndUserIdAndDate(fromDate:any,toDate:any,status:any,userId:any,pId: string): Observable<any> {
    return this._http.get(`${this.txnListByStatusAndUserIdAndDateUrl}`+'?pId=' + pId+'&fromDate='+fromDate+'&toDate='+toDate+'&status='+status+'&userId='+userId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public getAgentBenifPaymentTypes(agentId: any, pId: string): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._http.get(`${this.paymentTypeUrl}`+'?pId=' + pId+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public getAgentCollectionTypes(agentId: any,pId: string): Observable<any> {
    console.log("working Agent Collection Types.." + agentId);
    return this._http.get(`${this.collectiontypeUrl}` + '?pId=' + pId+'&agentId='+agentId).pipe(
      map(this.extractData)
    );
  }
  //getAgentCollectionListByAgentIdAndCurrencyUrl
  public getAgentCollectionListByAgentIdAndCurrency(agentId: any,currency:any,pId: string): Observable<any> {
    console.log("working Agent Collection Types.." + agentId);
    return this._http.get(`${this.getAgentCollectionListByAgentIdAndCurrencyUrl}` + '?pId=' + pId+'&agentId='+agentId+'&currency='+currency).pipe(
      map(this.extractData)
    );
  }

  public getSendingAgents(pId : string,functionality:any) : Observable <any> {
    console.log("working getSendingAgents="+pId);
    return this._httpp.get(`${this.sendingAgentsUrl}`+'?pId='+pId+'&functionality='+functionality,this.httpOptions).pipe(
    map(this.extractData)
    );
  }
  public getRecAgents(pId : string,srcAgentId:any) : Observable <any> {
    console.log("working getRecAgents="+pId);
    return this._httpp.get(`${this.recAgentsUrl}`+'?pId='+pId+'&srcAgentId='+srcAgentId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }


  public getExchangeRate(pId : string,srcAgentId:any,destAgentId:any,agentBenifPayTypeId:any) : Observable <any> {
    console.log("working getExchangeRate="+pId);
    return this._http.get(`${this.exchangerateUrl}`+'?pId='+pId+'&srcAgentId='+srcAgentId+'&destAgentId='+destAgentId+'&agentBenifPayTypeId='+agentBenifPayTypeId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }
  public getServiceFee(pId : string,srcAgentId:any,destAgentId:any,agentBenifPayTypeId:any,Amount:any) : Observable <any> {
    console.log("working getServiceFee="+pId);
    return this._http.get(`${this.serviceFeeUrl}`+'?pId='+pId+'&srcAgentId='+srcAgentId+'&destAgentId='+destAgentId+'&agentBenifPayTypeId='+agentBenifPayTypeId+'&Amount='+Amount,this.httpOptions).pipe(
    map(this.extractData)
    );
  }
  //getServiceFeeBySrcAndDestAgentAndPtypeAndCurrencyUrl
  public getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(pId :string,srcAgentId:any,destAgentId:any,agentBenifPayTypeId:any,Amount:any,srcCurrency:any,destCurrency:any) : Observable <any> {
    console.log("working getServiceFee="+pId);
    return this._httpp.get(`${this.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrencyUrl}`+'?pId='+pId+'&srcAgentId='+srcAgentId+'&destAgentId='+destAgentId+'&agentBenifPayTypeId='+agentBenifPayTypeId+'&Amount='+Amount+'&srcCurrency='+srcCurrency+'&destCurrency='+destCurrency,this.httpOptions).pipe(
    map(this.extractData)
    );
  }

  public getCollectionFee(pId : string,agentCollectionId:any,Amount:any) : Observable <any> {
    console.log("working getCollectionFee="+pId);
    return this._httpp.get(`${this.collectionFeeUrl}`+'?pId='+pId+'&agentCollectionId='+agentCollectionId+'&Amount='+Amount,this.httpOptions).pipe(
    map(this.extractData)

    );
  }

//createTxnWithIdProofUrl
public createTxnWithIdProoF(txnRecord: any,pId:string): Observable<any> {
//console.log("json ..!"+JSON.Stringify(txnRecord));
  return this._http.post(this.createTxnWithIdProofUrl + '?pId=' + pId, txnRecord, this.httpOptions).pipe(
    map(this.extractData)
  );
}




//CREATE TXN
  public createTxn(txnRecord: any,pId:string): Observable<any> {
//console.log("json ..!"+JSON.Stringify(txnRecord));
    return this._http.post(this.createTxnUrl + '?pId=' + pId, txnRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

public createTxnWithExistCustomer(txnRecord: any,pId:string): Observable<any> {
console.log("createTransactionWithExistCustomer ..!"+txnRecord);
  return this._http.post(this.createTxnUrl1 + '?pId=' + pId, txnRecord, this.httpOptions).pipe(
    map(this.extractData)
  );
}


  public getCustomerlist(agentId:any,pId:string):Observable<any>{
    return this._http.get(`${this.getcustomerlistApi}`+'?pId='+pId+'&agentId='+agentId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  

  public getCollectionByFields(pId:string,address:string,firstName:string,lastName:string,mobileNumber:string):Observable<any>{
    return this._http.get('${this.getCustomerListbyFieldsApi}'+'?address='+address+'&firstName='+firstName+'&lastName='+lastName+'&mobileNumber='+mobileNumber,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public saveBnkDetails(bnkRecord: any,pId:string): Observable<any> {
//console.log("json ..!"+JSON.Stringify(txnRecord));
    return this._http.post(this.saveBankDetails + '?pId=' + pId, bnkRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

    public getClientBanksList(pId:string):Observable<any>{
      return this._http.get(`${this.getBankList}`+'?pId='+pId,this.httpOptions).pipe(
        map(this.extractData)

      )
    }


        public getAgentCollPointList(pId:string,userId:string):Observable<any>{
          return this._http.get(`${this.getCashCollPointList}`+'?pId='+pId+'&userId='+userId,this.httpOptions).pipe(
            map(this.extractData)

          )
        }

        public saveCshCollDetails(cshRecord: any,pId:string): Observable<any> {
      //console.log("json ..!"+JSON.Stringify(txnRecord));
          return this._http.post(this.saveCshCollDetail + '?pId=' + pId, cshRecord, this.httpOptions).pipe(
            map(this.extractData)
          );
        }


        public getTxnListByStatus(fromDate:any,toDate:any,status:any,pId: string): Observable<any> {
          console.log('txnListByStatusUrl....', pId);
          return this._http.get(`${this.txnListByStatusUrl}`+'?pId=' + pId+'&status='+status+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
            map(this.extractData)
          )
        }
        public txnListByStatusAndKyc(fromDate:any,toDate:any,status:any,kycStatus:any,pId: string): Observable<any> {
          console.log('txnListByStatusAndKycUrl....', pId);
          return this._http.get(`${this.txnListByStatusAndKycs}`+'?pId=' + pId+'&status='+status+'&fromDate='+fromDate+'&toDate='+toDate+'&kycStatus='+kycStatus, this.httpOptions).pipe(
            map(this.extractData)
          )
        }
public txnListByStatusAndKycStatus(fromDate:any,toDate:any,status:any,kycStatus:any,pId: string): Observable<any> {
  console.log('txnListByStatusAndKycUrl....', pId);
  return this._http.get(`${this.txnListByStatusAndKycUrl}`+'?pId=' + pId+'&status='+status+'&fromDate='+fromDate+'&toDate='+toDate+'&kycStatus='+kycStatus, this.httpOptions).pipe(
    map(this.extractData)
  )
}

//txnListByStatusAndKycANdSendingAgentUrl
public txnListByStatusAndKycANdSendingAgent(agentId:any,fromDate:any,toDate:any,status:any,kycStatus:any,pId: string): Observable<any> {
  console.log('txnListByStatusAndKycANdSendingAgentUrl....', pId);
  return this._http.get(`${this.txnListByStatusAndKycANdSendingAgentUrl}`+'?pId='+pId+'&agentId='+agentId+'&status='+status+'&fromDate='+fromDate+'&toDate='+toDate+'&kycStatus='+kycStatus, this.httpOptions).pipe(
    map(this.extractData)
  )
}


public txnListByStatusAndKycANdRecAgent(pId: string,agentId:any,status:any,fromDate:any,toDate:any,kycStatus:any): Observable<any> {
  console.log('txnListByStatusAndKycANdRecAgent status check....', pId+"agentId"+agentId+"status"+status+"fromDate"+fromDate+"toDate"+toDate+"kycStatus"+kycStatus);
  return this._http.get(`${this.txnListByStatusAndKycANdRecAgentUrl}`+'?pId='+pId+'&agentId='+agentId+'&status='+status+'&fromDate='+fromDate+'&toDate='+toDate+'&kycStatus='+kycStatus, this.httpOptions).pipe(
    map(this.extractData)
  )
}

        public getTxnListBySrcAgentIdAndStatus(agentId:any,status:any, pId: string): Observable<any> {
          console.log('getTxnListBySrcAgentsAndStatus....', pId);
          return this._http.get(`${this.txnListBySrcAgentIdAndStatusUrl}`+'?pId=' + pId+'&agentId='+agentId+'&status='+status, this.httpOptions).pipe(
            map(this.extractData)
          )
        }
        public getCancelTxns(fromDate:any,toDate:any,pId: string): Observable<any> {
          console.log('getCancelTxns....', pId);
          return this._http.get(`${this.cancelTxnListUrl}`+'?pId=' + pId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
            map(this.extractData)
          )
        }

        public getRefundTxns(fromDate:any,toDate:any,pId: string): Observable<any> {
          console.log('getRefundTxns....', pId);
          return this._http.get(`${this.refundTxnUrl}`+'?pId=' + pId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
            map(this.extractData)
          )
        }


        public updateStatusByTxnId(transactionId:any,status:any,pId: string): Observable<any> {
          console.log('updateStatusByTxnId....', pId);
          return this._http.get(`${this.updateStatusByTxnIdUrl}`+'?pId=' + pId+'&transactionId='+transactionId+'&status='+status, this.httpOptions).pipe(
            map(this.extractData)
          )
        }



        public cancelTransaction(transactionId:any,cancelReason:any,cancelBy:any,pId: string): Observable<any> {
          console.log('cancelTransaction....', pId);
          return this._http.get(`${this.cancelTransactionUrl}`+'?pId=' + pId+'&transactionId='+transactionId+'&cancelReason='+cancelReason+'&cancelBy='+cancelBy, this.httpOptions).pipe(
            map(this.extractData)
          )
        }


        public refundTransaction(transactionId:any,refundReason:any,refundBy:any,pId: string): Observable<any> {
          console.log('refundTransaction....', pId);
          return this._http.get(`${this.refundTransactionUrl}`+'?pId=' + pId+'&transactionId='+transactionId+'&refundReason='+refundReason+'&refundBy='+refundBy, this.httpOptions).pipe(
            map(this.extractData)
          )
        }


        public sentTransactionsToCompliance(pId:any,record:any): Observable<any> {
          console.log('sentTransactionsToCompliance....', pId);
          return this._http.post(`${this.sentTransactionsToComplianceUrl}`+'?pId=' + pId,record, this.httpOptions).pipe(
            map(this.extractData)
          )
        }

//client pendingtxnListUrl
public getPendingtxnList(fromDate:any,toDate:any,pId: string): Observable<any> {
  console.log('pendingtxnListUrl....', pId);
  return this._http.post(`${this.pendingtxnListUrl}`+'?pId=' + pId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
    map(this.extractData)
  )
}

//Agent pendingtxnListBySendingAgentUrl
public getPendingtxnListBySendingAgent(agentId:any,fromDate:any,toDate:any,pId: string): Observable<any> {
  console.log('pendingtxnListBySendingAgentUrl....', pId);
  return this._http.get(`${this.pendingtxnListBySendingAgentUrl}`+'?pId=' + pId+'&agentId='+agentId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//Agent cancelTxnListBySendingAgentUrl
public getCancelTxnListBySendingAgent(agentId:any,fromDate:any,toDate:any,pId: string): Observable<any> {
  console.log('cancelTxnListBySendingAgentUrl....', pId);
  return this._http.get(`${this.cancelTxnListBySendingAgentUrl}`+'?pId=' + pId+'&agentId='+agentId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//refundTxnsBySendingAgentUrl
public getrefundTxnListBySendingAgent(agentId:any,fromDate:any,toDate:any,pId: string): Observable<any> {
  console.log('getrefundTxnListBySendingAgent....', pId);
  return this._http.get(`${this.refundTxnsBySendingAgentUrl}`+'?pId=' + pId+'&agentId='+agentId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//duplicateCustomerUrl
public getDuplicateCustomer(firstName:any,lastName:any,emailId:any,phoneNumber:any,pId: string): Observable<any> {
  console.log('getDuplicateCustomer....', pId);
  return this._httpp.get(`${this.duplicateCustomerUrl}`+'?pId=' + pId+'&firstName='+firstName+'&lastName='+lastName+'&emailId='+emailId+'&phoneNumber='+phoneNumber, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//duplicateBeneficiaryUrl
public getDuplicateBeneficiary(firstName:any,lastName:any,phoneNumber:any,pId: string): Observable<any> {
  console.log('duplicateBeneficiaryUrl....', pId);
  return this._http.get(`${this.duplicateBeneficiaryUrl}`+'?pId=' + pId+'&firstName='+firstName+'&lastName='+lastName+'&phoneNumber='+phoneNumber, this.httpOptions).pipe(
    map(this.extractData)
  )
}
public findDuplicateCustomerByEmailId(emailId:any,pId:string) : Observable <any>{
console.log('findDuplicateCustomerByEmailId====',pId)
  return this._http.get(`${this.duplicateCustomerUrl1}`+'?pId='+pId+'&emailId='+emailId,this.httpOptions).pipe(
    map(this.extractData)
  )

}
public getAllApiNames(pId:string) : Observable <any>{
console.log('getAllApiNames====',pId)
  return this._http.get(`${this.getAllApiNamesUrl}`+'?pId='+pId,this.httpOptions).pipe(
    map(this.extractData)
  )

}
//getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeUrl
public getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(pId : string,srcAgentId:any,destAgentId:any,agentBenifPayTypeId:any,srcCurrency:any,destCurrency:any,rateFlag:any) : Observable <any> {
  console.log("working getExchangeRate="+pId);
  return this._httpp.get(`${this.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeUrl}`+'?pId='+pId+'&srcAgentId='+srcAgentId+'&destAgentId='+destAgentId+'&agentBenifPayTypeId='+agentBenifPayTypeId+'&srcCurrency='+srcCurrency+'&destCurrency='+destCurrency+'&rateFlag='+rateFlag).pipe(
  map(this.extractData)
  );
}
//getAgentBenifPaymentTypeListByAgentIdAndCurrencyUrl
public getAgentBenifPaymentTypeListByAgentIdAndCurrency(agentId: any,currency:any, pId: string): Observable<any> {
  console.log('get  Agent payment types By agentId working', agentId);
  return this._httpp.get(`${this.getAgentBenifPaymentTypeListByAgentIdAndCurrencyUrl}`+'?pId=' + pId+'&agentId='+agentId+'&currency='+currency, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//getSendAgentShowListUrl
public getSendAgentShowList(pId: string): Observable<any> {
  console.log('getSendAgentShowListUrl....', pId);
  return this._httpp.get(`${this.getSendAgentShowListUrl}`+'?pId=' + pId, this.httpOptions).pipe(
    map(this.extractData)
  )
}
public getAllTransactionProfitList(pId: string): Observable<any> {
  console.log('getAllTransactionProfitListUrl....', pId);
  return this._http.get(`${this.getAllTransactionProfitListUrl}`+'?pId=' + pId, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getIdentityTypesBasedOnCountry(country:string,pId: string): Observable<any> {
  console.log('getIdentityTypes....', pId);
  return this._http.get(`${this.getIdentityTypesBasedOnCountryUrl}`+'?pId=' + pId+'&srcAgentId='+country, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public insertIdentityType(record:any,pId: string): Observable<any> {
  console.log('insertIdentityTypeUrl....', pId);
  return this._http.post(`${this.insertIdentityTypeUrl}`+'?pId=' + pId, record,this.httpOptions).pipe(
    map(this.extractData)
  )
}

//getColtnFeeByAgentIdAndSendAmountUrl
public getColtnTypesByAgentIdAndSendAmount(agentId: any,currency:any,display:any,amount:any, pId: string): Observable<any> {
  console.log('get  Agent payment types By agentId working', agentId);
  return this._httpp.get(`${this.getColtnFeeByAgentIdAndSendAmountUrl}`+'?pId=' + pId+'&agentId='+agentId+'&currency='+currency+'&display='+display+'&amount='+amount, this.httpOptions).pipe(
    map(this.extractData)
  )
}
public getTransactionDocuments(id:any,imageType:any,pId: string): Observable<any> {
  console.log('getTransactionDocuments....', pId);
  return this._http.get(`${this.getTransactionDocumentsUrl}`+'?pId=' + pId+'&transactionId='+id+'&imageType='+imageType, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getTransactionsProfitListByCreatedDate(fromDate:any,toDate:any,pId: string): Observable<any> {
  console.log('getTransactionsProfitListByCreatedDate....', pId);
  fromDate=fromDate+' 00:00:00';toDate=toDate+' 23:59:59';
  return this._http.get(`${this.getTransactionsProfitListByCreatedDateUrl}`+'?pId=' + pId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//retrieveTransactionListByCreatedDateUrl
public retrieveTransactionListByCreatedDate(pId: string,fromDate:any,toDate:any): Observable<any> {
  console.log('retrieveTransactionListByCreatedDateUrl....', pId);
  return this._http.get(`${this.retrieveTransactionListByCreatedDateUrl}`+'?pId=' + pId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//getComplainceListByApprovedDates
public getComplainceListByApprovedDate(fromDate:any,toDate:any,pId:string) : Observable <any>{
  console.log('getComplainceListByApprovedDate====',pId)
    return this._http.get(`${this.getComplainceListByApprovedDateUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate,this.httpOptions).pipe(
      map(this.extractData)
    )
  
  }
  public getRejectedListByRejectedDate(fromDate:any,toDate:any,pId:string) : Observable <any>{
    console.log('getRejectedListByRejectedDate====',pId)
      return this._http.get(`${this.getRejectedListByRejectedDateUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate,this.httpOptions).pipe(
        map(this.extractData)
      )
    
    }

public uploadAdditionalDocuments(transactionId:any,record:any,pId: string): Observable<any> {
  return this._httpp.post(`${this.uploadAdditionalDocumentsUrl}`+'?pId=' + pId+'&transactionId='+transactionId,record).pipe(
    map(this.extractData)
  )
}


public getCountrybyName(pId:string,countryName:any): Observable<any> {
  return this._http.get(`${this.getCountryByCountryName}`+'?pId=' + pId+'&countryName='+countryName).pipe(
  map(this.extractData)
)
}
public getCountriesFromReloadly(pId: string): Observable<any> {
  return this._httpp.get(`${this.getCountriesFromReloadlyUrl}` + '?pId=' + pId, this.httpOptions).pipe(
    map(this.extractData)
  );
}


public topUpPaymentDetails(pId: string, request: any): Observable<any> {
  return this._httpp.post(`${this.getTopUpPaymentDetails}?pId=${pId}`, request).pipe(
    map(this.extractData)
  );
}





public getBiller(pId: string, record: any): Observable<any> {
  // alert(record)
  return this._httpp.post(`${this.getBillersByCountryIsoUrl}` + '?pId=' + pId ,record, this.httpOptions).pipe(
    map(this.extractData)
  );
}

public reloadlyTransactionDetailsByCustomerId( pId: string,type:any): Observable<any> {
  console.log('reloadlyTransactionDetailsByCustomerId....', pId);
  return this._http.get(`${this.reloadlyTransactionDetailsByCustomerIdUrl}`+'?pId=' + pId+'&type='+type, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public createBillForReladly(pId : string,record:any) : Observable <any> {
  console.log("Record,,,," ,record)
  return this._httpp.post(`${this.createBillUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
  map(this.extractData)
  );
}
public saveTopupDetails(pId: string, request: any): Observable<any> {
  return this._httpp.post(`${this.saveTopUpDetailss}?pId=${pId}`, request).pipe(
    map(this.extractData)
  );
}



public getAllBillTransactions(pId: string, fromDate: any,toDate:any,paymentStatus:any): Observable<any> {
  // alert(record)
  fromDate=fromDate+' 00:00:00';toDate=toDate+' 23:59:59';
  return this._http.get(`${this.getAllBIllTransactionsURL}` + '?pId=' + pId + '&fromDate=' + fromDate + '&toDate=' + toDate + '&paymentStatus=' + paymentStatus, this.httpOptions).pipe(
    map(this.extractData)
  );
}



public updateStatusByBillTxnId(transactionId:any,status:any,pId: string): Observable<any> {
  console.log('updateStatusByAirTxnId....', pId);
  return this._http.get(`${this.updateStatusByBillTxnIdUrl}`+'?pId=' + pId+'&transactionId='+transactionId+'&status='+status,
   this.httpOptions)
}


public getAllAirTransactions(pId: string, fromDate: any,toDate:any,paymentStatus:any): Observable<any> {
  // alert(record)
  fromDate=fromDate+' 00:00:00';toDate=toDate+' 23:59:59';
  return this._http.get(`${this.getAllAirTransactionsURL}` + '?pId=' + pId + '&fromDate=' + fromDate + '&toDate=' + toDate + '&paymentStatus=' + paymentStatus, this.httpOptions).pipe(
    map(this.extractData)
  );
}

public updateStatusByAirTxnId(transactionId:any,status:any,pId: string): Observable<any> {
  console.log('updateStatusByAirTxnId....', pId);
  return this._http.get(`${this.updateStatusByAirTxnIdUrl}`+'?pId=' + pId+'&transactionId='+transactionId+'&status='+status,
   this.httpOptions)
}

public getAllOperatorsByPhoneAndCountryIso(pId: string, phoneNumber: any, countryIso: any): Observable<any> {
  return this._httpp.get(`${this.getAllOperatorsByPhoneAndCountryIsoUrl}` + '?pId=' + pId + '&phoneNumber=' + phoneNumber + '&countryIso=' + countryIso, this.httpOptions).pipe(
    map(this.extractData)
  );
}


public createNewTopUp(pId : string,record:any) : Observable <any> {
  console.log("Record,,,," ,record)
  return this._httpp.post(`${this.createTopUpUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
  map(this.extractData)
  );
}




public getVolumeTransactionStatus(paymentId: any, ): Observable<any> {
  return this._httpp.get(`${this.getVolumeTransactionStatusUrl}` + '?paymentId='+paymentId , this.httpOptions).pipe(
    map(this.extractData)
  );
}

}




function throwError(error: any): any {
  throw new Error('Function not implemented.');
}




