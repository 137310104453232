import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessIdGeneraterService {

  constructor() { }
    getPid(){
      var s=Date.now();
       console.log("userName in processIdGenerater..!"+localStorage.getItem("uname")+s);
        return localStorage.getItem("uname")+s;
      }
       
      getIpAdress(){
        // alert("ip")
        return "https://api.ipify.org/?format=json";
      }
}
