import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators'
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  
    apiUrl=environment.baseUrl;
    public autoDetectOperaters=`${this.apiUrl}/reloadly/autoDetectOperater/`;
    public getCountryListUrl=`${this.apiUrl}/reloadly/getCountries`;
    public getValidDocumentCustomerListUrl=`${this.apiUrl}/customer/validDocumentsCustomerList`;
    public documentsExpiredCustomerListUrl=`${this.apiUrl}/customer/documentsExpiredCustomerList`;
    public getBlockedCustomerListUrl=`${this.apiUrl}/customer/getBlockedCustomerList`;
    public sendComplainsSmsUrl=`${this.apiUrl}/customer/sendComplainsSmstoMobileNumber`;
  public customerApi:string = `${this.apiUrl}/customer/createBackendCustomer`;
  public customerListApi:string = `${this.apiUrl}/customer/getCustomersList`;
  public updateManualIdVerification: string=`${this.apiUrl}/customer/manualIdVerification`;
  public updateSanctionListStatus: string=`${this.apiUrl}/customer/sanctionFlagVerification`;
  public updateCashCollectionDetails: string=`${this.apiUrl}/transaction/updateCashCollInfo`;
  public saveMaxAmountDetails: string=`${this.apiUrl}/utility/saveMaxAmountDetails`;
  public getClientSendingCountryList: string=`${this.apiUrl}/keyclock/getClientSendingCountrys`;
  public getMaximumList: string=`${this.apiUrl}/utility/getMaxAmountList`;
  public getMaximumListByCountryId: string=`${this.apiUrl}/utility/getMaxAmountListByCountryId`;
  public updateMaxAmountDetails: string=`${this.apiUrl}/utility/updateMaxAmountDetails`;
  public customerListBySancFlag:string = `${this.apiUrl}/customer/getCustomersListBySanctionFlag`;
  public trnlisturl:string = `${this.apiUrl}/transaction/getListByTxndays`;
  public customerByIdUrl:string=`${this.apiUrl}/customer/getCustomerByUserId`;
  public customerByIddUrl:string=`${this.apiUrl}/customer/getImage`;
  public beneficiaryListByCustomerIdUrl:string =`${this.apiUrl}/customer/getBeneficiaryListByCustomerId`;
 public beneficiaryListByCustomerIddUrl:string =`${this.apiUrl}/customer/getBeneficiaryListByCustomerIdAndPaymentTypeAndCountryId`;
  
public getCustomerDocumentsByuserIdUrl=`${this.apiUrl}/customer/getCustomerDocumentsByUserId`;
public getCustomersListByAgentIdUrl :string = `${this.apiUrl}/customer/getCustomersListByAgentId`;

public changeBlockedStatusUrl=`${this.apiUrl}/customer/changeCustomerBlockUnblockStatus`;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))

  };
  httpOptions1 = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
    //.set('Authorization', localStorage.getItem("datas"))

  };


  constructor(private _http:HttpClient) { }

  public extractData(res:Response):Array<any>| {} {
    const body=res;
    console.log('res',res);
     return body || {} ;
  }

  public getCustomerByIdd(userId:any,imageType:any,pId: string): Observable<any> {
    console.log('getCustomerById....', pId);
    return this._http.get(`${this.customerByIddUrl}`+'?pId=' + pId+'&userId='+userId+'&imageType='+imageType, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public postCustomer(pId:string,record:any):Observable<any>{
    return this._http.post(`${this.customerApi}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getCustomerList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.customerListApi}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateManualIdVerificationData(pId:any,userId:any,idVerifiedStatus:any):Observable<any>{
    console.log("processId..!"+pId+","+userId+","+idVerifiedStatus);
    return this._http.post(`${this.updateManualIdVerification}`+'?pId='+pId+'&userId='+userId+'&idVerifiedStatus='+idVerifiedStatus,this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public sendComplainsSmstoCustomerMobileNumber(number:any,pId: string): Observable<any> {
    return this._http.post(`${this.sendComplainsSmsUrl}`+'?pId=' + pId+'&number='+number, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  



  public changeBlockedStatus(pId:string,userId:string,status:any,reason:any):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.post(`${this.changeBlockedStatusUrl}`+'?pId='+pId+'&userId='+userId+'&status='+status+'&reason='+reason,this.httpOptions).pipe(
      map(this.extractData)
    )
  }













  public updateSanctionListData(pId:any,userId:any,sanctionFlagStatus:any):Observable<any>{
    console.log("processId..!"+pId+","+userId+","+sanctionFlagStatus);
    return this._http.post(`${this.updateSanctionListStatus}`+'?pId='+pId+'&userId='+userId+'&sanctionFlagStatus='+sanctionFlagStatus,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateCashPickUpDetail(cashCollectionPointId:number,updateRecord:any,pId : string){
    return this._http.post(`${this.updateCashCollectionDetails}`+'?pId='+pId+'&cashCollectionPointId='+cashCollectionPointId,updateRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  public saveMaxAmount(maxAmountRecord:any,pId : string){
    return this._http.post(`${this.saveMaxAmountDetails}`+'?pId='+pId,maxAmountRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  public getClientSendCountryList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getClientSendingCountryList}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getMaxAmntList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getMaximumList}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getMaxAmntListByCountryId(pId:string,countryId:any):Observable<any>{
    console.log("pId..!"+pId+".."+countryId);
    return this._http.get(`${this.getMaximumListByCountryId}`+'?pId='+pId+'&countryId='+countryId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateMaxAmount(maxAmountRecord:any,pId : string,maxAmountId:string){
    console.log("maxAmountId..!"+maxAmountId);
    return this._http.post(`${this.updateMaxAmountDetails}`+'?pId='+pId+'&maxAmountId='+maxAmountId,maxAmountRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  public getCustomerListBySanctionFlag(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.customerListBySancFlag}`+'?pId='+pId+'&sanctionSearch='+'yes',this.httpOptions).pipe(
      map(this.extractData)
    )
  }
//trnlisturl
public getTransactionLIstByCustId(Id:any,days:any,pId:string):Observable<any>{
  console.log("pI d..!"+pId);
  return this._http.get(`${this.trnlisturl}`+'?pId='+pId+'&sendingCustomerUserId='+Id+'&days='+days,this.httpOptions).pipe(
    map(this.extractData)
  )
}


public getCustomerById(userId:any,pId: string): Observable<any> {
  console.log('getCustomerById....', pId);
  return this._http.get(`${this.customerByIdUrl}`+'?pId=' + pId+'&userId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public beneficiaryListByCustomerId(Id:any, pId: string): Observable<any> {

console.log('beneficiaryListByCustomerIdUrl....', pId,Id);
return this._http.get(`${this.beneficiaryListByCustomerIdUrl}`+'?pId=' + pId+'&customerId='+Id, this.httpOptions1).pipe(
map(this.extractData)
)
}
public beneficiaryListByCustomerIdd(Id:any, paymentType: string,countryId:string): Observable<any> {

  console.log('beneficiaryListByCustomerIdUrl....',Id);
  return this._http.get(`${this.beneficiaryListByCustomerIddUrl}`+'?customerId='+Id+'&paymentType=' + paymentType+'&countryId=' + countryId, this.httpOptions1).pipe(
  map(this.extractData)
  )
  }

public getCustomerDocumentsByuserId(userId:any,pId: string): Observable<any> {
console.log('getCustomerDocumentsByuserIdUrl....', pId);
return this._http.get(`${this.getCustomerDocumentsByuserIdUrl}`+'?pId=' + pId+'&userId='+userId, this.httpOptions1).pipe(
map(this.extractData)
)
}

public getBlockedCustomerList(pId:string,status:any):Observable<any>{
  console.log("pId..!"+pId);
  return this._http.get(`${this.getBlockedCustomerListUrl}`+'?pId='+pId+'&status='+status,this.httpOptions).pipe(
    map(this.extractData)
  )
}
   //getCustomersListByAgentIdUrl
   public getCustomersListByAgentId(pId: string,agentId:any): Observable<any> {
    return this._http.get(`${this.getCustomersListByAgentIdUrl}` + '?pId=' + pId+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }


  public documentsExpiredCustomerList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.documentsExpiredCustomerListUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getValidDocumentCustomerList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getValidDocumentCustomerListUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getCountryList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getCountryListUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public autoDetectOperater(pId: string,countryCode: string, phoneNumber: string, suggestedAmountsMap: string, suggestedAmounts: string): Observable<any> {
    const params = new HttpParams()
     .set('pId', pId)
      .set('suggestedAmountsMap', suggestedAmountsMap)
      .set('suggestedAmounts', suggestedAmounts)
    const url = `${this.autoDetectOperaters}${phoneNumber}/${countryCode}`;
    return this._http.get(url, { params: params, headers: this.httpOptions.headers }).pipe(
      map(this.extractData)
    );
  }


  // public getOpertaterByIsoCode(pId: string,countryCode: string): Observable<any> {
  //   const params = new HttpParams()
  //    .set('pId', pId)
  //    .set('countryCode', countryCode)

  //   const url = `${this.apiUrl}/reloadly/getOperaterByIsoCode/${countryCode}`+'?pId='+pId;
  //   return this._http.get(url, { params: params, headers: this.httpOptions.headers }).pipe(
  //     map(this.extractData)
  //   );
  }

    