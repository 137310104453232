import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient,HttpHeaders } from '@angular/common/http';
import { map,tap } from 'rxjs/operators';
import {Observable} from 'rxjs';
import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  apiUrl=environment.baseUrl;
//getTransactionLimitListByCountryId
public getUserFormActonsByUserId:string=`${this.apiUrl}/user/getUserFormActonsBuUserId`;
public getUserIdBySubUserId: string=`${this.apiUrl}/user/getUserIdByRoleId`;
public userFormActionsByRoleCreatedId: string=`${this.apiUrl}/user/getUserFormActonsByRoleCreatedId`;
public saveRoleUser: string=`${this.apiUrl}/user/saveRoleUser`;
public getCountryList:string=`${this.apiUrl}/keyclock/getClientCountrys`;
public rolesList:string =`${this.apiUrl}/user/getRoleCreatedList`;
public savemapping: string =`${this.apiUrl}/user/saveRoleMapping`;
  public getAdminList1: string =`${this.apiUrl}/user/getByUserTypeId`;
  public getTrnxList1 : string = `${this.apiUrl}/utility/getTranxListByTxndays`;
  public getTrnxDaysByCountryId : string = `${this.apiUrl}/utility/getTransactionLimitListByCountryId`;
  public saveTrnxDays : string = `${this.apiUrl}/utility/saveTransactionLimitDays`;
  public baseUrl : string = `${this.apiUrl}/agent/getRevenueRates`;
  public saveAgentDepositData:string=`${this.apiUrl}/agent/createAgentDeposits`;
  public getAgentDepositList : string = `${this.apiUrl}/agent/getAgentDepositListBWDates`;
  public getAgentDepositListById : string = `${this.apiUrl}/agent/getAgentDepositList`;
  public getAgentDepositListByClient : string = `${this.apiUrl}/agent/getAgentDepositListByClient`;
  public updateAgentDepositByDepositId : string =`${this.apiUrl}/agent/updateAgentDepositByDepositId`;
  public updateAgentDepositByRecAgentId : string =`${this.apiUrl}/agent/updateAgentDepositByRecAgentId`;
  public revenuePerListByClient : string =`${this.apiUrl}/utility/getRevenuePercentageList`;
  public updateRevenuePercentageDetails:string=`${this.apiUrl}/utility/updateRevenuePercentageDetails`;
  public ledgerCl: string=`${this.apiUrl}/transaction/getAllTransactionList`;
  
  public getTransactionReportBySendingAgentUrl: string =`${this.apiUrl}/utility/getTransactionReportBySendingAgent`;

  public ledgerClByRecAgent: string=`${this.apiUrl}/transaction/getAllTransactionListByRecAgent`;
  public openBalCl: string=`${this.apiUrl}/transaction/getOpeningalByAgentIdAndFromDate`;
  public saveTxDaysListUrl:string=`${this.apiUrl}/utility/saveTxnDaysLimitDetails`;
  public getTxDaysListUrl:string=`${this.apiUrl}/utility/getTxnDaysLimitList`;
  public updateTxDaysListUrl:string=`${this.apiUrl}/utility/updateTxnDaysLimit`;
  public getTxDaysListBySrcAgentUrl:string=`${this.apiUrl}/utility/getTxnDaysLimitBySrcAgentId`;
  public listBySendingCustomerId:string = `${this.apiUrl}/transaction/getTransactionListBySendingCustomerUserId`;
  public getCustomerListByStatusUrl:string=`${this.apiUrl}/customer/getCustomerListByStatus`
public getAllBeneficiariesUrl:string=`${this.apiUrl}/customer/getAllBeneficiaries`
public getAllBeneficiariesBasedOnTextUrl:string=`${this.apiUrl}/customer/getAllBeneficiariesBasedOnText`
public getAgentsListByFunctionaliti:string=`${this.apiUrl}/user/getAgentsListByFunctionality`;
public getAdminAgentDepositList:string=`${this.apiUrl}/agent/getAdminAgentDepositList`;
public getDepositListByRecAgentId:string=`${this.apiUrl}/agent/getRecAgentDepositList`;
public insertIdentityTypeUrls:string=`${this.apiUrl}/utility/saveIdentityTypes`;
public updateCustomerUrl: string = `${this.apiUrl}/utility/updateCustomer`;
public getCountryDetailsBasedOnCountryIdUrl:string=`${this.apiUrl}/utility/getCountryDetailsBasedOnCountryId`;
public ExchangeperListByClient : string =`${this.apiUrl}/utility/getExchangeProfitPercentageList`;
public baseUrl1 : string = `${this.apiUrl}/agent/getExchangeRate`;
public getUserListByStatusUrl:string =`${this.apiUrl}/user/getUserDetailsBasedOnStatus`;
public updateEmaiIdUrl: string = `${this.apiUrl}/user/updateEmail`;
public createPaymentUrl: string = `${this.apiUrl}/transaction/createVolumePayment`;
public getResonForTransferByIdUrl: string = `${this.apiUrl}/utility/getResonForTransfer`;


  httpOptions = {
        headers : new HttpHeaders ({'content-type' : 'application/json'})
        //.set('Authorization', localStorage.getItem("datas"))
  };
  constructor(private _http:HttpClient,private _httpp:HttpClient,
    handler: HttpBackend) {
      this._httpp = new HttpClient(handler);
    }
  private  extractData( res: Response ) : Array <any> | {} {
    const body = res;
    console.log('res ',res)
    return body || {} ;
   }

   public getUserFormActonByUserId(pId: string,userId: any): Observable<any> {
         return this._http.get(`${this.getUserFormActonsByUserId}`+'?pId=' + pId+'&userId=' + userId, this.httpOptions).pipe(
         map(this.extractData)
       )
   }

   public getUserIdbySubUserId(pId: string,userId: any): Observable<any> {
         return this._http.get(`${this.getUserIdBySubUserId}`+'?pId=' + pId+'&userId=' + userId, this.httpOptions).pipe(
         map(this.extractData)
       )
   }

   public getUserFormActonsByRoleCreatedIdList(pId: string,roleCreatedId: any): Observable<any> {
         return this._http.get(`${this.userFormActionsByRoleCreatedId}`+'?pId=' + pId+'&roleCreatedId=' + roleCreatedId, this.httpOptions).pipe(
         map(this.extractData)
       )
   }

   public createRoleWithUser(roleUserRecord:any,pId : string): Observable <any>{
        return this._http.post(`${this.saveRoleUser}`+'?pId='+pId,roleUserRecord,this.httpOptions)
        .pipe(
        map(this.extractData)
        );
  }
   public getCountrysList(pId: string): Observable<any> {
         return this._httpp.get(`${this.getCountryList}`+'?pId=' + pId, this.httpOptions).pipe(
         map(this.extractData)
       )
   }
   public getRoleCreatedList(pId: string): Observable<any> {
         return this._http.get(`${this.rolesList}`+'?pId=' + pId, this.httpOptions).pipe(
         map(this.extractData)
       )
   }
   public createRole(branchRecord:any,pId : string): Observable <any>{
        return this._http.post(`${this.savemapping}`+'?pId='+pId,branchRecord,this.httpOptions)
        .pipe(
        map(this.extractData)
        );
  }
   public getAdminList(pId: string,userTypeId:any): Observable<any> {
         return this._http.get(`${this.getAdminList1}`+'?pId=' + pId+'&userTypeId='+userTypeId, this.httpOptions).pipe(
         map(this.extractData)
       )
   }
   public getAgentsDepositListByrecAgentId(pId: string,recAgentId:any): Observable<any> {
         return this._http.get(`${this.getDepositListByRecAgentId}`+'?pId=' + pId+'&recAgentId='+recAgentId, this.httpOptions).pipe(
         map(this.extractData)
       )
   }


     public getAdminReceiveAgentsDepositList(pId: string): Observable<any> {
           return this._http.get(`${this.getAdminAgentDepositList}`+'?pId=' + pId, this.httpOptions).pipe(
           map(this.extractData)
         )
     }
  public getAgentsListByFunctionality(pId: string,functionality:any): Observable<any> {
        return this._http.post(`${this.getAgentsListByFunctionaliti}`+'?pId=' + pId+'&functionality='+functionality, this.httpOptions).pipe(
        map(this.extractData)
      )
  }
   public getTransactionList1(pId: string,sendingCustomerUserId:any,days:any): Observable<any> {
         return this._http.get(`${this.getTrnxList1}`+'?pId=' + pId+'&sendingCustomerUserId='+sendingCustomerUserId+'&days='+days, this.httpOptions).pipe(
       map(this.extractData)
     )
   }


   public getBeneficiariesBasedOnText(pId:string,text:any): Observable<any> {
     return this._http.get(this.getAllBeneficiariesBasedOnTextUrl+ '?pId=' + pId+'&text='+text, this.httpOptions).pipe(
      map(this.extractData)
      );
    }


   public getAllBeneficiaries(pId:string): Observable<any> {
         return this._http.get(this.getAllBeneficiariesUrl+ '?pId=' + pId, this.httpOptions).pipe(
          map(this.extractData)
          );
        }

   public getCustomerListByStatus(pId:string,status:any): Observable<any> {
   return this._http.get(this.getCustomerListByStatusUrl+ '?pId=' + pId+'&status='+status, this.httpOptions).pipe(
    map(this.extractData)
    );
   }
   public getRevenuePercentageRates(pId: string,srcAgentId:any,recAgentId:any): Observable<any> {
         return this._httpp.get(`${this.baseUrl}`+'?pId=' + pId+'&srcAgentId='+srcAgentId+'&recAgentId='+recAgentId, this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public createAgentDepositDetails(agentDepositRecord: any,pId:string): Observable<any> {

              return this._http.post(this.saveAgentDepositData + '?pId=' + pId, agentDepositRecord, this.httpOptions).pipe(
                map(this.extractData)
              );
            }
  public getAgentList(pId: string,srcAgentId:any,fromDate:any,toDate:any): Observable<any> {
    fromDate=fromDate+' 00:00:00';
    toDate=toDate+' 23:59:59';
                  return this._http.get(`${this.getAgentDepositList}`+'?pId=' + pId+'&userId='+srcAgentId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
                map(this.extractData)
              )
            }
            public getAgentListBySenderId(pId: string,srcAgentId:any): Observable<any> {

          return this._http.get(`${this.getAgentDepositListById}`+'?pId=' + pId+'&userId='+srcAgentId, this.httpOptions).pipe(
                          map(this.extractData)
                        )
                      }
        public getAgentListByClient(pId: string): Observable<any> {
          return this._http.get(`${this.getAgentDepositListByClient}`+'?pId=' + pId, this.httpOptions).pipe(
                      map(this.extractData)
                                  )
                                }

      public updateAgentDepositDetails(pId:string,depositedId:any,depositedStatus:any): Observable<any> {
      return this._http.put(this.updateAgentDepositByDepositId + '?pId=' + pId+'&agentDepositId='+depositedId+'&depositedStatus='+depositedStatus, this.httpOptions).pipe(
       map(this.extractData)
       );
     }
     public getRevPercListByClient(pId: string): Observable<any> {
           return this._http.get(`${this.revenuePerListByClient}`+'?pId=' + pId, this.httpOptions).pipe(
         map(this.extractData)
       )
     }


     //this method is used for create new branch
      public updateRevPercDetails(pId : string,revRecord:any,revenuePercentageId:any): Observable <any>{
console.log("update record..!"+JSON.stringify(revRecord));
         return this._http.post(`${this.updateRevenuePercentageDetails}`+'?pId='+pId+'&revenuePercentageId='+revenuePercentageId,revRecord,this.httpOptions)
           .pipe(
           map(this.extractData)
           );
     }
     public getOpeningBalanceList(pId: string,agentId:any,fromDate:any): Observable<any> {
           fromDate=fromDate+' 00:00:00';
           return this._http.get(`${this.openBalCl}`+'?pId=' + pId+'&agentId='+agentId+'&fromDate='+fromDate, this.httpOptions).pipe(
         map(this.extractData)
       )
     }
     public getTrnxListBySendAgentIdWithDates(pId: string,agentId:any,fromDate:any,toDate:any): Observable<any> {
           fromDate=fromDate+' 00:00:00';toDate=toDate+' 23:59:59';
           return this._http.get(`${this.ledgerCl}`+'?pId=' + pId+'&agentId='+agentId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
         map(this.extractData)
       )
     }


//saveTxDaysListUrl

public saveTxDaysList(pId : string,record:any): Observable <any>{
console.log("saveTxDaysListUrl..!"+JSON.stringify(record));
   return this._http.post(`${this.saveTxDaysListUrl}`+'?pId='+pId,record,this.httpOptions)
     .pipe(
     map(this.extractData)
     );
}
//getTxDaysListUrl
public getTxDaysList(pId: string): Observable<any> {
      return this._http.get(`${this.getTxDaysListUrl}`+'?pId=' + pId, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//updateTxDaysListUrl
public updateTxDaysList(pId:string,Id:any,record:any): Observable<any> {
return this._http.put(this.updateTxDaysListUrl + '?pId='+ pId+'&txnDaysLimitId='+Id,record, this.httpOptions).pipe(
 map(this.extractData)
 );
}
//getTxDaysListBySrcAgentUrl
public getTxDaysListBySrcAgent(pId: string,agentId:any): Observable<any> {
      return this._http.get(`${this.getTxDaysListBySrcAgentUrl}`+'?pId=' + pId+'&agentId='+agentId, this.httpOptions).pipe(
    map(this.extractData)
  )
}
public getTransactionListBySendingCustomerUserId(Id:any, pId: string): Observable<any> {
  console.log('TransactionListBySendingCustomerUserId....', pId);
  return this._http.get(`${this.listBySendingCustomerId}`+'?pId=' + pId+'&sendingCustomerUserId='+Id, this.httpOptions).pipe(
    map(this.extractData)
  )
}

   public createTrnxLimitDetails(transactionLimitRecord: any,pId:string): Observable<any> {
              return this._http.post(this.saveTrnxDays + '?pId=' + pId, transactionLimitRecord, this.httpOptions).pipe(
                map(this.extractData)
              );
            }

public getTrnxLimitDetailsByCountry(countryId: any,pId:string): Observable<any> {
           return this._http.get(this.getTrnxDaysByCountryId + '?pId=' + pId+ '&countryId=' + countryId,  this.httpOptions).pipe(
             map(this.extractData)
           );
         }
         public updateAgentDepositDetailsByRecAgentId(pId:string,depositedId:any,depositedStatus:any,recAgentId:any): Observable<any> {
         return this._http.put(this.updateAgentDepositByRecAgentId + '?pId=' + pId+'&agentDepositId='+depositedId+'&depositedStatus='+depositedStatus+'&recAgentId='+recAgentId, this.httpOptions).pipe(
          map(this.extractData)
          );
        }
        public getTrnxListByRecAgentIdWithDates(pId: string,agentId:any,fromDate:any,toDate:any): Observable<any> {
              fromDate=fromDate+' 00:00:00';toDate=toDate+' 23:59:59';
              return this._http.get(`${this.ledgerClByRecAgent}`+'?pId=' + pId+'&agentId='+agentId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
            map(this.extractData)
          )
        }
        public getTransactionReportBySendingAgent(pId: string,userId: any,fromDate:any,toDate:any): Observable<any> {
          fromDate=fromDate+' 00:00:00';toDate=toDate+' 23:59:59';
          return this._http.get(`${this.getTransactionReportBySendingAgentUrl}`+'?pId=' + pId+'&userId=' + userId+'&fromDate='+fromDate+'&toDate='+toDate, this.httpOptions).pipe(
          map(this.extractData)
        )
      }

        public insertIdentityTypes(record:any,pId: string): Observable<any> {
          console.log('insertIdentityTypeUrl....', pId);
          return this._http.post(`${this.insertIdentityTypeUrls}`+'?pId=' + pId, record,this.httpOptions).pipe(
            map(this.extractData)
          )
        }
        public updateCustomer(record:any,userId:any,pId:string): Observable<any> {
            return this._http.put(this.updateCustomerUrl+ '?pId=' + pId+'&userId='+userId,record, this.httpOptions).pipe(
             map(this.extractData)
             );
            }

            public getCountryDetailsBasedOnCountryId(pId:string,userId:any): Observable<any> {
              return this._http.get(this.getCountryDetailsBasedOnCountryIdUrl+ '?pId=' + pId+'&countryId='+userId, this.httpOptions).pipe(
               map(this.extractData)
               );
              }


              public getExchangeProfitListByClient(pId: string): Observable<any> {
                return this._http.get(`${this.ExchangeperListByClient}`+'?pId=' + pId, this.httpOptions).pipe(
              map(this.extractData)
            )
          }

          public getExchangeRtePercentageRates(pId: string,srcAgentId:any,recAgentId:any): Observable<any> {
            return this._http.get(`${this.baseUrl1}`+'?pId=' + pId+'&srcAgentId='+srcAgentId+'&recAgentId='+recAgentId, this.httpOptions).pipe(
          map(this.extractData)
        )
        }
        public getUserListByStatus(status: any,pId: string): Observable<any> {
          return this._http.get(`${this.getUserListByStatusUrl}`+'?pId=' + pId+'&status=' + status, this.httpOptions).pipe(
          map(this.extractData)
        )
    }




    public updateEmailId(record:any): Observable<any> {
      return this._http.post(this.updateEmaiIdUrl,record, this.httpOptions).pipe(
       map(this.extractData)
       );
      }
        // public getIPaddress() {
        //   this._httpp.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
        //     // this.ipAddress = res.ip;
        //     alert(res.ip)
        //     return res.ip
            
        //   });
          
        // }
        createPaymentIntent(paymentData: any): Observable<any> {
          const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
          return this._http.post(this.createPaymentUrl, paymentData, this.httpOptions).pipe(
            map(this.extractData)
            );
           }

           public resonForTransferById(Id: string): Observable<any> {
            return this._http.get(`${this.getResonForTransferByIdUrl}`+'?reasonForTransferId=' + Id, this.httpOptions).pipe(
            map(this.extractData)
          )
      }

}
