import { Injectable } from '@angular/core';
import {HttpHeaders,HttpClient,HttpErrorResponse} from '@angular/common/http';
import {Observable,throwError} from 'rxjs';
import { catchError, map} from 'rxjs/operators';

import { Router,ActivatedRoute} from '@angular/router';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LogOutService {
  apiUrl=environment.baseUrl;

  public logoutUrl : string = `${this.apiUrl}/user/logout`;

  httpOptions = {
        headers : new HttpHeaders ({'content-type' : 'application/json'



            // 'Access-Control-Allow-Credentials' : 'true',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
            // 'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'

      })




    };

    constructor(  private _http:HttpClient,private router :Router,private _route:ActivatedRoute) { }



      private  extractData( res: Response ) : Array <any> | {} {
        const body = res;
        console.log('res ',res)
        return body || {} ;
       }



   // logOut() {
   //   if (localStorage.removeItem('datas') == null && localStorage.removeItem('uname') == null) {
   //     this.router.navigate(['/logout']);
   //   }
   // }
   public logOut(): Observable <any>{
     return this._http.post(this.logoutUrl,this.httpOptions).pipe(
       map(this.extractData)
     );
  }


}
