import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessLogServiceService {
  apiUrl=environment.baseUrl
  public getAccessLogListByDatesUrl: string = `${this.apiUrl}/access/getAccessLogListByLogOnDateAndLogOffDate`;
    public saveAccessLogUrl: string = `${this.apiUrl}/access/saveAccessLog`;
    public getAccessLogByAccessIdAndUserIdUrl: string = `${this.apiUrl}/access/getAccessLogByAccessIdAndUserId`;
    public updateAccessLogByAccessIdUrl: string = `${this.apiUrl}/access/updateAccessLogByAccessId`;
    public getAccessLogListUrl: string = `${this.apiUrl}/access/getAccessLogList`;
    public getAccessLogListByDatesAndUserTypeAndUserNameUrl: string = `${this.apiUrl}/access/getAccessLogListByLogOnDateAndLogOffDateAndUserTypeAnduserName`;
    public getAccessLogListByDatesAndUserTypeUrl: string = `${this.apiUrl}/access/getAccessLogListByLogOnDateAndLogOffDateAndUserType`;
    public getAccessLogListByBetweenDatesAndUserNameUrl: string = `${this.apiUrl}/access/getAccessLogListByBetweenDatesAndUserName`;
public getAccessLogListByUserNameUrl: string = `${this.apiUrl}/access/getAccessLogListByUserName`;
public getAccessLogListByUserTypeUrl: string = `${this.apiUrl}/access/getAccessLogListByUserType`;
public getAccessLogListByUserNameAndUserTypeUrl: string = `${this.apiUrl}/access/getAccessLogListByUserNameAndUserType`;

    httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json' })
      //.set('Authorization', localStorage.getItem("datas"))

    };

    constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute) { }

    private extractData(res: Response): Array<any> | {} {
      const body = res;
      console.log('res ', res)
      return body || {};
    }
    public getAccessLogListByUserNameAndUserType(userType: any,userName:String,pId: string): Observable<any> {
    console.log("getAccessLogListByUserNameAndUserTypeUrl  list.." + pId);
    return this._http.get(`${this.getAccessLogListByUserNameAndUserTypeUrl}` + '?pId=' + pId+'&userType='+userType+'&userName='+userName, this.httpOptions).pipe(
      map(this.extractData)
    );
  }


    //getAccessLogListByUserTypeUrl
    public getAccessLogListByUserType(userType:String,pId: string): Observable<any> {
      console.log("getAccessLogListByUserTypeUrl  list.." + pId);
      return this._http.get(`${this.getAccessLogListByUserTypeUrl}` + '?pId=' + pId+'&userType='+userType, this.httpOptions).pipe(
        map(this.extractData)
      );
    }

    public getAccessLogListByUserName(userName:String,pId: string): Observable<any> {
        console.log("getAccessLogListByUserNameUrl  list.." + pId);
        return this._http.get(`${this.getAccessLogListByUserNameUrl}` + '?pId=' + pId+'&userName='+userName, this.httpOptions).pipe(
          map(this.extractData)
        );
      }

      public getAccessLogListByBetweenDatesAndUserName(logOnDate: any,logOffDate:any,userName:String,pId: string): Observable<any> {
        console.log("getAccessLogListByBetweenDatesAndUserNameUrl  list.." + pId);
        return this._http.get(`${this.getAccessLogListByBetweenDatesAndUserNameUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate+'&userName='+userName, this.httpOptions).pipe(
          map(this.extractData)
        );
      }
    //getAccessLogListByDatesUrl
    public getAccessLogListByDates(logOnDate:any,logOffDate:any,pId: string): Observable<any> {
      console.log("getAccessLogListByDatesUrl  list.." + pId);
      return this._http.get(`${this.getAccessLogListByDatesUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate, this.httpOptions).pipe(
        map(this.extractData)
      );
    }


    //getAccessLogListByDatesAndUserTypeUrl
    public getAccessLogListByDatesAndUserType(logOnDate:any,logOffDate:any,userType:String,pId: string): Observable<any> {
      console.log("getAccessLogListByDatesAndUserTypeUrl  list.." + pId);
      return this._http.get(`${this.getAccessLogListByDatesAndUserTypeUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate+'&userType='+userType, this.httpOptions).pipe(
        map(this.extractData)
      );
    }


    public getAccessLogListByDatesAndUserTypeAndUserName(logOnDate:any,logOffDate:any,userType:String,userName:any,pId: string): Observable<any> {
      console.log("getAccessLogListByDatesAndUserTypeAndUserNameUrl  list.." + pId);
      return this._http.get(`${this.getAccessLogListByDatesAndUserTypeAndUserNameUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate+'&userType='+userType+'&userName='+userName, this.httpOptions).pipe(
        map(this.extractData)
      );
    }

    public getAccessLogList(pId: string): Observable<any> {
        console.log("getAccessLogListUrl  list.." + pId);
        return this._http.get(`${this.getAccessLogListUrl}` + '?pId=' + pId, this.httpOptions).pipe(
          map(this.extractData)
        );
      }


public updateAccessLogByAccessId(accessId:any,pId:string):Observable<any>{

  return this._http.get(`${this.updateAccessLogByAccessIdUrl}`+`?pId=`+pId+'&accessId='+accessId,this.httpOptions).pipe(
    map(this.extractData)
  )
}


    public getAccessLogByAccessIdAndUserId(accessId: any,userId:String,pId: string): Observable<any> {
        console.log("getAccessLogByAccessIdAndUserIdUrl  list.." + pId);
        return this._http.get(`${this.getAccessLogByAccessIdAndUserIdUrl}` + '?pId=' + pId+'&accessId='+accessId+'&userId='+userId, this.httpOptions).pipe(
          map(this.extractData)
        );
      }

    public saveAccessLog(record: any,pId:string): Observable<any> {
      console.log('saveAccessLogUrl....',record);
      return this._http.post(this.saveAccessLogUrl + '?pId=' + pId, record).pipe(
        map(this.extractData)
      );
    }
}
