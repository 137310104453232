import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'remitzNew';




  constructor(

   public bnIdle: BnNgIdleService,
    public router:Router,

  ){
    this.bnIdle.startWatching(120000).subscribe((isTimedOut:boolean) =>{

      if(isTimedOut){
        // alert('session expired')
console.log('session expired');
// alert("expired")
localStorage.clear();
this.router.navigate(['/']);

      }

    })

  }
}
