import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class VelocityrulesService {
    apiUrl=environment.baseUrl;
public velocityListUrl:string=`${this.apiUrl}/utility/getVelocityList`;
public getVelocityListBySrcCountryIdUrl:string=`${this.apiUrl}/utility/getVelocityListBySrcCountry`;
public getVelocityListByDestCountryIdUrl:string=`${this.apiUrl}/utility/getVelocityListByDestCountry`;
public getVelocityListByStatusUrl:string=`${this.apiUrl}/utility/getVelocityListByStatus`;
public getVelocityListByPeriodTypeUrl:string=`${this.apiUrl}/utility/getVelocityListByPeriodType`;
public addvelocityUrl:string=`${this.apiUrl}/utility/saveVelocityRules`;
public getVelocityListByVelocityIdUrl:string=`${this.apiUrl}/utility/getVelocityListByVelocityId`;
public updateVelocityRuleUrl:string=`${this.apiUrl}/utility/updateVelocityRule`;
    httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json' })
    };

    constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute) { }
    private extractData(res: Response): Array<any> | {} {
      const body = res;
      console.log('res ', res)
      return body || {};
    }
    //getVelocityListByVelocityIdUrl
    public getVelocityListByVelocityId(id: any,pId: string): Observable<any> {
      console.log("getVelocityListByVelocityIdUrl  list.." + pId);
      return this._http.get(`${this.getVelocityListByVelocityIdUrl}` + '?pId=' + pId+'&id='+id, this.httpOptions).pipe(
        map(this.extractData)
      );
    }

    public getVelocityListByPeriodType(periodType: string,pId: string): Observable<any> {
      console.log("getVelocityListByPeriodTypeUrl  list.." + pId);
      return this._http.get(`${this.getVelocityListByPeriodTypeUrl}` + '?pId=' + pId+'&periodType='+periodType, this.httpOptions).pipe(
        map(this.extractData)
      );
    }

    //getVelocityListByStatusUrl
      public getVelocityListByStatus(status: string,pId: string): Observable<any> {
        console.log("getVelocityListByStatusUrl  list.." + pId);
        return this._http.get(`${this.getVelocityListByStatusUrl}` + '?pId=' + pId+'&status='+status, this.httpOptions).pipe(
          map(this.extractData)
        );
      }

    //getVelocityListByDestCountryIdUrl
    public getVelocityListByDestCountryId(destCounryId: string,pId: string): Observable<any> {
      console.log("getVelocityListByDestCountryIdUrl  list.." + pId);
      return this._http.get(`${this.getVelocityListByDestCountryIdUrl}` + '?pId=' + pId+'&destCounry='+destCounryId, this.httpOptions).pipe(
        map(this.extractData)
      );
    }
    //velocityListUrl
    public velocityList(pId:string): Observable<any> {
      console.log('velocityListUrl....',pId);
      return this._http.get(this.velocityListUrl + '?pId=' + pId, this.httpOptions).pipe(
        map(this.extractData)
      );
    }
    //getVelocityListBySrcCountryIdUrl
      public getVelocityListBySrcCountryId(srcCounryId: string,pId: string): Observable<any> {
        console.log("getVelocityListBySrcCountryIdUrl  list.." + pId);
        return this._http.get(`${this.getVelocityListBySrcCountryIdUrl}` + '?pId=' + pId+'&srcCounry='+srcCounryId, this.httpOptions).pipe(
          map(this.extractData)
        );
      }

      //addvelocityUrl
      public addvelocity(record: any,pId:string): Observable<any> {
        console.log('addvelocityUrl....',pId);
        return this._http.post(this.addvelocityUrl + '?pId=' + pId, record, this.httpOptions).pipe(
          map(this.extractData)
        );
      }
      public updateVelocityRule(id:any,record:any,pId : string){
        return this._http.put(`${this.updateVelocityRuleUrl}`+'?pId='+pId+'&id='+id,record,this.httpOptions).pipe(
            map(this.extractData)
        )
      }


}
