import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelloWorldComponentComponent } from './hello-world-component/hello-world-component.component';
import { AccessloggModule } from './remitz/accesslogg/accesslogg.module';
import { AddrateModule } from './remitz/addrate/addrate.module';
import { AdminCustomerprofileeRoutingModule } from './remitz/admin-customerprofilee/admin-customerprofilee-routing.module';
import { AdminCustomerprofileeModule } from './remitz/admin-customerprofilee/admin-customerprofilee.module';
import { AdminagentdeposittModule } from './remitz/adminagentdepositt/adminagentdepositt.module';
import { AdminledgerrModule } from './remitz/adminledgerr/adminledgerr.module';
import { AgentCollectionMarkuppModule } from './remitz/agent-collection-markupp/agent-collection-markupp.module';
import { AgentMappinggModule } from './remitz/agent-mappingg/agent-mappingg.module';
import { AgentdepositlisttModule } from './remitz/agentdepositlistt/agentdepositlistt.module';
import { AgentdepositttModule } from './remitz/agentdeposittt/agentdeposittt.module';
import { AgenttModule } from './remitz/agentt/agentt.module';
import { AgentwithcreateeModule } from './remitz/agentwithcreatee/agentwithcreatee.module';
import { BankDetailssModule } from './remitz/bank-detailss/bank-detailss.module';
import { BankssModule } from './remitz/bankss/bankss.module';
import { BeneficierylisttModule } from './remitz/beneficierylistt/beneficierylistt.module';
import { BranchcreateeModule } from './remitz/branchcreatee/branchcreatee.module';
import { BranchhModule } from './remitz/branchh/branchh.module';
import { CashpickuppModule } from './remitz/cashpickupp/cashpickupp.module';
import { ComplanceapprovallModule } from './remitz/complanceapprovall/complanceapprovall.module';
import { CustomerlisttModule } from './remitz/customerlistt/customerlistt.module';
import { CustomerrModule } from './remitz/customerr/customerr.module';
import { DestAgentExchRatessModule } from './remitz/dest-agent-exch-ratess/dest-agent-exch-ratess.module';
import { DestinationAgentTrListtModule } from './remitz/destination-agent-tr-listt/destination-agent-tr-listt.module';
import { EmaillModule } from './remitz/emaill/emaill.module';
import { ExchangeRateeModule } from './remitz/exchange-ratee/exchange-ratee.module';
import { ExchangeRatessModule } from './remitz/exchange-ratess/exchange-ratess.module';
import { KycapprovallModule } from './remitz/kycapprovall/kycapprovall.module';
import { LedgerrModule } from './remitz/ledgerr/ledgerr.module';
import { ManagebrandpaymentssModule } from './remitz/managebrandpaymentss/managebrandpaymentss.module';
import { PaymenttypeeModule } from './remitz/paymenttypee/paymenttypee.module';
import { ProfitlosssModule } from './remitz/profitlosss/profitlosss.module';
import { ReceipttModule } from './remitz/receiptt/receiptt.module';
import { ReceiveDepositListtModule } from './remitz/receive-deposit-listt/receive-deposit-listt.module';
import { RecledgerrModule } from './remitz/recledgerr/recledgerr.module';
import { RelationShip11Module } from './remitz/relation-ship11/relation-ship11.module';
import { RelationShippModule } from './remitz/relation-shipp/relation-shipp.module';
import { RevenuepercentageModule } from './remitz/revenuepercentage/revenuepercentage.module';
import { RoleUserrModule } from './remitz/role-userr/role-userr.module';
import { RoleeModule } from './remitz/rolee/rolee.module';
import { SactionlisttModule } from './remitz/sactionlistt/sactionlistt.module';
import { SellratesModule } from './remitz/sellrates/sellrates.module';
import { ServiceFeeeModule } from './remitz/service-feee/service-feee.module';
import { SourceofFounddModule } from './remitz/sourceof-foundd/sourceof-foundd.module';
import { SrcAgentExchRatessModule } from './remitz/src-agent-exch-ratess/src-agent-exch-ratess.module';
import { TransactionlistlimitssModule } from './remitz/transactionlistlimitss/transactionlistlimitss.module';
import { TransactionlisttModule } from './remitz/transactionlistt/transactionlistt.module';
import { TransactionreviewwModule } from './remitz/transactionrevieww/transactionrevieww.module';
import { TranscationnModule } from './remitz/transcationn/transcationn.module';
import { TxdlistModule } from './remitz/txdlist/txdlist.module';
import { VelocityyModule } from './remitz/velocityy/velocityy.module';


const routes: Routes = [
  {
path:'admin',
loadChildren:()=>import("./remitz/logingg/logingg.module").then(m=>m.LoginggModule)
  },
  {
    path:'identitytype',
    loadChildren:() =>import("./remitz/identitytypee/identitytypee.module").then(m=> m.IdentitytypeeModule)
  },
  {
    path:'agentcreate',
    loadChildren:()=>import("./remitz/agentwithcreatee/agentwithcreatee.module").then(m=>m.AgentwithcreateeModule)
  }
,
{
  path:'apiinfo',
  loadChildren:()=>import("./remitz/apiinfoo/apiinfoo.module").then(m=>m.ApiinfooModule)
},
{
  path:'paymenttype',
  loadChildren:()=>import("./remitz/paymenttypee/paymenttypee.module").then(m=>m.PaymenttypeeModule)
},
{
  path:'adminCustomerprofile',
  loadChildren:()=>import("./remitz/admin-customerprofilee/admin-customerprofilee.module").then(m=>m.AdminCustomerprofileeModule)
}
,
{
  path:'transaction',
  loadChildren:()=>import("./remitz/transcationn/transcationn.module").then(m=>m.TranscationnModule)

}  ,
{
  path:'agent',
  loadChildren:()=>import("./remitz/agentt/agentt.module").then(m=>m.AgenttModule)

},
{
  path:'agentdepositlist',
  loadChildren:()=>import("./remitz/agentdepositlistt/agentdepositlistt.module").then(m=>m.AgentdepositlisttModule)
}
,
{
  path:'branch',
  loadChildren:()=>import("./remitz/branchh/branchh.module").then(m=>m.BranchhModule)
},
{
  path:"sellrate",
  loadChildren:()=>import("./remitz/sellrates/sellrates.module").then(m=>m.SellratesModule)
},
{
  path:'agentwith',
  loadChildren:()=>import("./remitz/agent-withh/agent-withh.module").then(m=>m.AgentWithhModule)
},
{
  path:'complainceapproval',
  loadChildren:()=>import("./remitz/complanceapprovall/complanceapprovall.module").then(m=>m.ComplanceapprovallModule)
},
{
  path:'exchangerate',
  loadChildren:()=>import("./remitz/exchange-ratee/exchange-ratee.module").then(m=>m.ExchangeRateeModule)
},{
  path:'servicefee',
  loadChildren:()=>import("./remitz/service-feee/service-feee.module").then(m=>m.ServiceFeeeModule)
},
{
  path:'agentmarkup',
  loadChildren:()=>import("./remitz/agent-collection-markupp/agent-collection-markupp.module").then(m=>m.AgentCollectionMarkuppModule)
},
{
  path:'profitloss',
  loadChildren:()=>import("./remitz/profitlosss/profitlosss.module").then(m=>m.ProfitlosssModule)
},
{
  path:'velocity',
  loadChildren:()=>import("./remitz/velocityy/velocityy.module").then(m=>m.VelocityyModule)
},
{
  path:'audittrail',
  loadChildren:()=>import("./remitz/audit-traill/audit-traill.module").then(m=>m.AuditTraillModule)
},
{
  path:'servicefee',
  loadChildren:()=>import("./remitz/service-feee/service-feee.module").then(m=>m.ServiceFeeeModule)
},
{
  path:'ledger',
  loadChildren:()=>import("./remitz/ledgerr/ledgerr.module").then(m=>m.LedgerrModule)
},
{
  path:'sourceoffund',
  loadChildren:()=>import('./remitz/sourceof-foundd/sourceof-foundd.module').then(m=>m.SourceofFounddModule)
},
{
  path:'emailtemplate',
  loadChildren:()=>import("./remitz/emaill/emaill.module").then(m=>m.EmaillModule)
},
{
  path:'relationship',
  loadChildren:()=>import("./remitz/relation-shipp/relation-shipp.module").then(m=>m.RelationShippModule)
  
},
{
  path:'bankdetails',
  loadChildren:()=>import("./remitz/bank-detailss/bank-detailss.module").then(m=>m.BankDetailssModule)
},
{
  path:'reasonForTransfer',
  loadChildren:()=>import("./remitz/relation-ship11/relation-ship11.module").then(m=>m.RelationShip11Module)
},
{
  path:'CustomerManualIdVerification',
  loadChildren:()=>import("./remitz/kycapprovall/kycapprovall.module").then(m=>m.KycapprovallModule)
},{
  path:'agentmapping',
  loadChildren:()=>import("./remitz/agent-mappingg/agent-mappingg.module").then(m=>m.AgentMappinggModule)
},{
  path:'addrates',
  loadChildren:()=>import("./remitz/addrate/addrate.module").then(m=>m.AddrateModule)
},
{
  path:'revenuePercentage',
  loadChildren:()=>import("./remitz/revenuepercentage/revenuepercentage.module").then(m=>m.RevenuepercentageModule)
},
{
  path:'manageBranchPayments',
  loadChildren:()=>import("./remitz/managebrandpaymentss/managebrandpaymentss.module").then(m=>m.ManagebrandpaymentssModule)
},
{
  path:'maxAmount',
  loadChildren:()=>import("./remitz/maxamountt/maxamountt.module").then(m=>m.MaxamounttModule)
},
{
  path:'txDaysLimit',
  loadChildren:()=>import("./remitz/txdlist/txdlist.module").then(m=>m.TxdlistModule)
},
{
  path:'exchage',
  loadChildren:()=>import("./remitz/exchange-ratess/exchange-ratess.module").then(m=>m.ExchangeRatessModule)
},
{
  path:'srcAgentExchRate',
  loadChildren:()=>import("./remitz/src-agent-exch-ratess/src-agent-exch-ratess.module").then(m=>m.SrcAgentExchRatessModule)
},
{
  path:'destAgentExchRates',
  loadChildren:()=>import("./remitz/dest-agent-exch-ratess/dest-agent-exch-ratess.module").then(m=>m.DestAgentExchRatessModule)
},
{
  path:'accesslog',
  loadChildren:()=>import("./remitz/accesslogg/accesslogg.module").then(m=>m.AccessloggModule)
},
{
  path:'customer',
  loadChildren:()=>import("./remitz/customerr/customerr.module").then(m=>m.CustomerrModule)
},
{
  path:'transactionlist',
  loadChildren:()=>import("./remitz/transactionlistt/transactionlistt.module").then(m=>m.TransactionlisttModule)
},
{
  path:'customerlist',
  loadChildren:()=>import("./remitz/customerlistt/customerlistt.module").then(m=>m.CustomerlisttModule)
},
{
  path:'adminledger',
  loadChildren:()=>import("./remitz/adminledgerr/adminledgerr.module").then(m=>m.AdminledgerrModule)
},
{
  path:'trasactionlist',
  loadChildren:()=>import("./remitz/sactionlistt/sactionlistt.module").then(m=>m.SactionlisttModule)
},
{
  path:'transactionreview',
  loadChildren:()=>import("./remitz/transactionrevieww/transactionrevieww.module").then(m=>m.TransactionreviewwModule)

},{
  path:'cashpickup',
  loadChildren:()=>import("./remitz/cashpickupp/cashpickupp.module").then(m=>m.CashpickuppModule)

},
{
  path:'banks',
  loadChildren:()=>import("./remitz/bankss/bankss.module").then(m=>m.BankssModule)

},
{
  path:'receipt',
  loadChildren:()=>import("./remitz/receiptt/receiptt.module").then(m=>m.ReceipttModule)
},
{
  path:'transactionList',
  loadChildren:()=>import("./remitz/transactionlistt/transactionlistt.module").then(m=>m.TransactionlisttModule)
},
{
  path:'agentdeposit',
  loadChildren:()=>import("./remitz/agentdeposittt/agentdeposittt.module").then(m=>m.AgentdepositttModule)
},{
  path:'dtransactiOnlist',
  loadChildren:()=>import("./remitz/destination-agent-tr-listt/destination-agent-tr-listt.module").then(m=>m.DestinationAgentTrListtModule)

},
{
  path:'adminagentdeposit',
  loadChildren:()=>import("./remitz/adminagentdepositt/adminagentdepositt.module").then(m=>m.AdminagentdeposittModule)

},
{
  path:'beneficierylist',
  loadChildren:()=>import("./remitz/beneficierylistt/beneficierylistt.module").then(m=>m.BeneficierylisttModule)
},
{
  path:'trnxlimit',
  loadChildren:()=>import("./remitz/transactionlistlimitss/transactionlistlimitss.module").then(m=>m.TransactionlistlimitssModule)

},
{
  path:'adminagentdepositt',
  loadChildren:()=>import("./remitz/adminagentdepositt/adminagentdepositt.module").then(m=>m.AdminagentdeposittModule)

},{
  path:'recDepositApprovalList',
  loadChildren:()=>import("./remitz/receive-deposit-listt/receive-deposit-listt.module").then(m=>m.ReceiveDepositListtModule)

},
{
  path:'receivingLedger',
  loadChildren:()=>import("./remitz/recledgerr/recledgerr.module").then(m=>m.RecledgerrModule)

},
{
  path:'roles',
  loadChildren:()=>import("./remitz/rolee/rolee.module").then(m=>m.RoleeModule)

},
{
  path:'roleUser',
  loadChildren:()=>import("./remitz/role-userr/role-userr.module").then(m=>m.RoleUserrModule)
},
{
  path:'branchcreate',
  loadChildren:()=>import("./remitz/branchcreatee/branchcreatee.module").then(m=>m.BranchcreateeModule)
},{
  path:'email',
  loadChildren:()=>import("./remitz/create-emaill/create-emaill.module").then(m=>m.CreateEmaillModule)
}
//front
,{
  path:'calculater',
  loadChildren:()=>import("./remitzfront/calculatorr/calculatorr.module").then(m=>m.CalculatorrModule)
},
{
  path:'index',
  loadChildren:()=>import("./remitzfront/indexdashboardd/indexdashboardd.module").then(m=>m.IndexdashboarddModule)
},
{
path:'',
loadChildren:()=>import("./remitzfront/indexx/indexx.module").then(m=>m.IndexxModule)
},
{
  path:'payment',
  loadChildren:()=>import("./remitzfront/paymentt/paymentt.module").then(m=>m.PaymenttModule)
},
{
  path:'signin',
  loadChildren:()=>import("./remitzfront/loginn/loginn.module").then(m=>m.LoginnModule)

},{
  path:'forget',
  loadChildren:()=>import("./remitzfront/forgett/forgett.module").then(m=>m.ForgettModule)

},
{
  path:'signup',
  loadChildren:()=>import("./remitzfront/signupp/signupp.module").then(m=>m.SignuppModule)

},
{
  path:'recipient',
  loadChildren:()=>import("./remitzfront/recipientt/recipientt.module").then(m=>m.RecipienttModule)
},
{
  path:'addrecipient',
  loadChildren:()=>import("./remitzfront/addrecipientt/addrecipientt.module").then(m=>m.AddrecipienttModule)

},
{
  path:'addpersonaldetails',
  loadChildren:()=>import("./remitzfront/addpersonaldetails/addpersonaldetails.module").then(m=>m.AddpersonaldetailsModule)

},
{
  path:'changepassword',
  loadChildren:()=>import("./remitzfront/Myaccount/changepasswordgg/changepasswordgg.module").then(m=>m.ChangepasswordggModule)

},
{
  path:'document',
  loadChildren:()=>import("./remitzfront/Myaccount/documentt/documentt.module").then(m=>m.DocumenttModule)
},
{
  path:'addnewrecipient',
  loadChildren:()=>import("./remitzfront/Myaccount/addnewrecipientt/addnewrecipientt.module").then(m=>m.AddnewrecipienttModule)

},
{
  path:'transactionlistt',
  loadChildren:()=>import("./remitzfront/Myaccount/transactionlistt/transactionlistt.module").then(m=>m.TransactionlisttModule)

},
{
  path:'updateprofile',
  loadChildren:()=>import("./remitzfront/Myaccount/updateprofilee/updateprofilee.module").then(m=>m.UpdateprofileeModule)

},
{
  path:'transactionrevieww',
  loadChildren:()=>import("./remitzfront/txnrevieww/txnrevieww.module").then(m=>m.TxnreviewwModule)
},
{
  path:'my_account_receipt',
  loadChildren:()=>import("./remitzfront/Myaccount/my-acnt-recipientt/my-acnt-recipientt.module").then(m=>m.MyAcntRecipienttModule)
},
{
  path:'receiptt',
  loadChildren:()=>import("./remitzfront/Myaccount/recipittt/recipittt.module").then(m=>m.RecipitttModule)
},
{
  path:'success',
  loadChildren:()=>import("./remitzfront/success/success.module").then(m=>m.SuccessModule)
},
  { path: 'receipttt', loadChildren: () => import('./remitz/receipttt/receipttt.module').then(m => m.ReceiptttModule) },
  { path: 'receiptttt', loadChildren: () => import('./remitz/receipt/receipt.module').then(m => m.ReceiptModule) },
  { path: 'areceiptttt', loadChildren: () => import('./remitz/areceipt/areceipt.module').then(m => m.AreceiptModule) },
  { path: 'receipptt', loadChildren: () => import('./remitzfront/receipt/receipt.module').then(m => m.ReceiptModule) },
  { path: 'sendingagenttrlist', loadChildren: () => import('./remitz/sendingagenttrlist/sendingagenttrlist.module').then(m => m.SendingagenttrlistModule) },
  { path: 'about', loadChildren: () => import('./remitzfront/about/about.module').then(m => m.AboutModule) },
  { path: 'moneytransfer', loadChildren: () => import('./remitzfront/moneytranser/moneytranser.module').then(m => m.MoneytranserModule) },
  { path: 'currancyexchange', loadChildren: () => import('./remitzfront/currancyexchange/currancyexchange.module').then(m => m.CurrancyexchangeModule) },
  { path: 'sendingcustomer', loadChildren: () => import('./remitz/sendingcustomer/sendingcustomer.module').then(m => m.SendingcustomerModule) },
  { path: 'privacypolicy', loadChildren: () => import('./remitzfront/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule) },
  { path: 'emailverificationn', loadChildren: () => import('./remitzfront/emailverficationn/emailverficationn.module').then(m => m.EmailverficationnModule) },
  { path: 'exchrateprofitdistr', loadChildren: () => import('./remitz/exchrateprofitdistr/exchrateprofitdistr.module').then(m => m.ExchrateprofitdistrModule) },

  { path: 'partialcustomerlist', loadChildren: () => import('./remitz/partialcustomerlist/partialcustomerlist.module').then(m => m.PartialcustomerlistModule) },

  { path: 'changepasswordadmin', loadChildren: () => import('./remitz/changepasswordadmin/changepasswordadmin.module').then(m => m.ChangepasswordadminModule) },
  { path: 'sendinagentprofitloss', loadChildren: () => import('./remitz/sendingagentprofitloss/sendingagentprofitloss.module').then(m => m.SendingagentprofitlossModule) },
  { path: 'recivingagentprofitloss', loadChildren: () => import('./remitz/recivingagentprofitloss/recivingagentprofitloss.module').then(m => m.RecivingagentprofitlossModule) },
  { path: 'partialcustomerlist', loadChildren: () => import('./remitz/partialcustomerlist/partialcustomerlist.module').then(m => m.PartialcustomerlistModule) },
  { path: 'partialcustomer', loadChildren: () => import('./remitz/partialcustomer/partialcustomer.module').then(m => m.PartialcustomerModule) },
  { path: 'approvelist', loadChildren: () => import('./remitz/approvelist/approvelist.module').then(m => m.ApprovelistModule) },
  { path: 'compliance', loadChildren: () => import('./remitz/compliance/compliance.module').then(m => m.ComplianceModule) },
  { path: 'rejectedtransactions', loadChildren: () => import('./remitz/rejectedtransactions/rejectedtransactions.module').then(m => m.RejectedtransactionsModule) },
  { path: 'blockedcustomer', loadChildren: () => import('./remitz/blockedcustomer/blockedcustomer.module').then(m => m.BlockedcustomerModule) },
  {  path: 'compliancedocuments/:id', loadChildren: () => import('./compliancedocuments/compliancedocuments.module').then(m => m.CompliancedocumentsModule) },
  { path: 'compliancecustomer', loadChildren: () => import('./remitz/compliancecustomer/compliancecustomer.module').then(m => m.CompliancecustomerModule) },
  { path: 'compliancecustomer', loadChildren: () => import('./remitz/compliancecustomer/compliancecustomer.module').then(m => m.CompliancecustomerModule) },
  
  { path: 'approvelistt', loadChildren: () => import('./remitz/approvelistt/approvelistt.module').then(m => m.ApprovelisttModule) },
  { path: 'CompletedCustomerList', loadChildren: () => import('./remitz/completed-customer-list/completed-customer-list.module').then(m => m.CompletedCustomerListModule) },
  { path: 'MigratedCustomerList', loadChildren: () => import('./remitz/migrated-customer-list/migrated-customer-list.module').then(m => m.MigratedCustomerListModule) },
  { path: 'ExpiredCustomerList', loadChildren: () => import('./remitz/expired-customer-list/expired-customer-list.module').then(m => m.ExpiredCustomerListModule) },
  { path: 'indexx', loadChildren: () => import('./remitz/imdexx/imdexx.module').then(m => m.ImdexxModule) },
  { path: 'topup', loadChildren: () => import('./remitzfront/Myaccount/topup/topup.module').then(m => m.TopupModule) },
  { path: 'topuppayment', loadChildren: () => import('./remitzfront/Myaccount/topuppayment/topuppayment.module').then(m => m.TopuppaymentModule) },
  { path: 'topuppaymentt', loadChildren: () => import('./remitzfront/Myaccount/topuppaymentt/topuppaymentt.module').then(m => m.TopuppaymenttModule) },
  { path: 'billpayment', loadChildren: () => import('./remitzfront/Myaccount/billpayment/billpayment.module').then(m => m.BillpaymentModule) },
  { path: 'airpayment-type', loadChildren: () => import('./remitzfront/airpayment-type/airpayment-type.module').then(m => m.AirpaymentTypeModule) },
  { path: 'success-reloadly', loadChildren: () => import('./remitzfront/Myaccount/success-reloadly/success-reloadly.module').then(m => m.SuccessReloadlyModule) },
  { path: 'billers', loadChildren: () => import('./remitzfront/Myaccount/billers/billers.module').then(m => m.BillersModule) },
  { path: 'bill-transactions', loadChildren: () => import('./remitz/bill-transactions/bill-transactions.module').then(m => m.BillTransactionsModule) },
  { path: 'air-transactions', loadChildren: () => import('./remitz/air-transactions/air-transactions.module').then(m => m.AirTransactionsModule) },
  { path: 'smstemplate', loadChildren: () => import('./remitz/smstemplate/smstemplate.module').then(m => m.SmstemplateModule) },
  { path: 'volumecallback', loadChildren: () => import('./remitzfront/volumecallback/volumecallback.module').then(m => m.VolumecallbackModule) },
  { path: 'volume', loadChildren: () => import('./remitzfront/volume/volume.module').then(m => m.VolumeModule) },
  { path: 'change-password', loadChildren: () => import('./remitzfront/change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'splashScreen', loadChildren: () => import('./splash-screen/splash-screen.module').then(m => m.SplashScreenModule) },

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:false})],
  exports: [RouterModule],
  providers:[{provide:LocationStrategy, useClass:PathLocationStrategy}
  ]
})
export class AppRoutingModule { }
