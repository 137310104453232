import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MatSelectModule} from '@angular/material/select';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy  } from '@angular/common';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


import {NgxPaginationModule} from 'ngx-pagination';
import { NgSearchFilterModule } from 'ng-search-filter';
import {MatDialogModule} from '@angular/material/dialog';


// ngselect
import { NgSelectModule } from '@ng-select/ng-select';

import { CookieService } from 'ngx-cookie-service';

import { HelloWorldComponentComponent } from './hello-world-component/hello-world-component.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DestreceiptComponent } from './remitz/destreceipt/destreceipt.component';
import { UpdateComponent } from './remitz/update/update.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { OpwelComponent } from './opwel/opwel.component';
import { MatFormFieldModule } from '@angular/material/form-field';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { TokenInterceptorService } from './token-interceptor.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxCaptchaModule } from 'ngx-captcha';
@NgModule({
  declarations: [
    AppComponent,
    DestreceiptComponent,
    UpdateComponent,
    OpwelComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule, CarouselModule ,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    MatDialogModule,
    MatSelectModule,NgSelectModule ,MatFormFieldModule , 
    MatSelectModule,MatDatepickerModule,MatNativeDateModule,NgxCaptchaModule,

    NgxPaginationModule,NgSearchFilterModule,
    MatAutocompleteModule,
    NgbModule







  ],
  providers: [
    BnNgIdleService,
        {provide : LocationStrategy , useClass: PathLocationStrategy},
        {
          provide:HTTP_INTERCEPTORS,
          useClass:TokenInterceptorService,
          multi:true
        }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
