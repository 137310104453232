import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentCollectionMarkupService {
  apiUrl=environment.baseUrl;
  public collectiontypeUrl: string = `${this.apiUrl}/agent/getAgentCollectionList`;
  public colltypeUrl: string = `${this.apiUrl}/agent/getAllAgentCollectionList`;
  public sendingAgentList: string = `${this.apiUrl}/user/getAgentsListByFunctionality`;
  public MaxValUrl: string=`${this.apiUrl}/agent/getAgentCollectionMarkupMaxValue`;
  public MarkupTypeListUrl: string=`${this.apiUrl}/utility/getMarkupTypeList`;
  public  agentcoltnMrkpListUrl: string=`${this.apiUrl}/agent/getAgentCollectionMarkupListByAgentColtnId`;
  public updateAgentColtnMrkpUrl: string=`${this.apiUrl}/agent/updateAgentCollectionMarkupById`;
  public saveAgentColtnMrkpUrl:string=`${this.apiUrl}/agent/createAgentCollectionMarkup`;
  public deleteAgentColtnMrkpUrl: string=`${this.apiUrl}/agent/deleteAgentCollectionMarkup`;
public deleteAgentColtnMaxAmount: string=`${this.apiUrl}/agent/deleteAgentCollectionFeeBySrcAgentAndCollType`;
public updateCollectionTypeStatusUrl: string=`${this.apiUrl}/agent/updateCollectionTypeStatus`;
  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute) { }

  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res ', res)
    return body || {};
  }

  //updateCollectionTypeStatusUrl
  public updateCollectionTypeStatus(agentCollectionId: any,status:any,pId:string):Observable <any>{
   return this._http.get(`${this.updateCollectionTypeStatusUrl}` + `?pId=` +pId+'&agentCollectionId='+agentCollectionId.split("-")[0]+'&status='+status,this.httpOptions).pipe(
     map(this.extractData)
   )
  }
  public deleteAgentCollectionMarkupValue(pId:string,agentCollectionId:any,agentId:any):Observable<any>{
  console.log("working deleteAgentCollectionMarkup="+pId+"..agentCollectionId="+agentCollectionId+" agentId."+agentId);
  return this._http.delete(`${this.deleteAgentColtnMaxAmount}`+'?pId='+pId+'&agentCollTypeId='+agentCollectionId+'&srcAgentId='+agentId,this.httpOptions).pipe(
      map(this.extractData)
  )
}

  public getMarkupTypeList(pId: string): Observable<any> {
    console.log("working markup type list.." + pId);
    return this._http.get(`${this.MarkupTypeListUrl}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getAgentCollectionTypes(agentId: any,pId: string): Observable<any> {
    console.log("working Agent Collection Types.." + agentId);
    return this._http.get(`${this.collectiontypeUrl}` + '?pId=' + pId+'&agentId='+agentId).pipe(
      map(this.extractData)
    );
  }
  public getAllAgentCollTypes(agentId: any,pId: string): Observable<any> {
    console.log("working Agent Collection Types.." + agentId);
    return this._http.get(`${this.colltypeUrl}` + '?pId=' + pId+'&agentId='+agentId).pipe(
      map(this.extractData)
    );
  }
  public getSendingAgents( pId: string,req:any): Observable<any> {
    console.log('get Sending Agent working', req);
    return this._http.post(`${this.sendingAgentList}` + '?pId=' + pId,req).pipe(
      map(this.extractData)
    )
  }

 public getCollectionFeeMaxVal(agentCollectionId: any,pId:string):Observable <any>{
console.log('get collection fee max val working',agentCollectionId);
  return this._http.get(`${this.MaxValUrl}` + `?pId=` +pId+'&agentCollectionId='+agentCollectionId,this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getAgentCollectionMarkupListByAgentColtnId(agentCollectionId: any,pId:string):Observable <any>{
console.log('get Agent collection Markup List working',agentCollectionId);
 return this._http.get(`${this.agentcoltnMrkpListUrl}` + `?pId=` +pId+'&agentCollectionId='+agentCollectionId,this.httpOptions).pipe(
   map(this.extractData)
 )
}

public updateAgentCollectionMarkup(agentColtnMrkpRecord:any,pId:string,agentCollectionMarkupId:number){
  console.log("working updateAgentCollectionMarkup="+pId+"..agentCollectionMarkupId="+agentCollectionMarkupId);
  console.log("working updateAgentCollectionMarkup="+JSON.stringify(agentColtnMrkpRecord));
  return this._http.put(`${this.updateAgentColtnMrkpUrl}`+'?pId='+pId+'&agentCollectionMarkupId='+agentCollectionMarkupId,agentColtnMrkpRecord,this.httpOptions).pipe(
      map(this.extractData)
  )
}


public createAgentCollectionMarkup(agentColtnMrkpRecord: any,pId:string): Observable<any> {
  console.log('agentColtnMrkpRecord',pId);
  return this._http.post(this.saveAgentColtnMrkpUrl + '?pId=' + pId, agentColtnMrkpRecord, this.httpOptions).pipe(
    map(this.extractData)
  );
}
public deleteAgentCollectionMarkup(pId:string,agentCollectionId:number){
  console.log("working deleteAgentCollectionMarkup="+pId+"..agentCollectionId="+agentCollectionId);
  return this._http.put(`${this.deleteAgentColtnMrkpUrl}`+'?pId='+pId+'&agentCollectionId='+agentCollectionId,this.httpOptions).pipe(
      map(this.extractData)
  )
}


}
