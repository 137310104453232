import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-destreceipt',
  templateUrl: './destreceipt.component.html',
  styleUrls: ['./destreceipt.component.css']
})
export class DestreceiptComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
