import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs'
import { map } from 'rxjs/operators';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class ServiceFeeService {
    apiUrll=environment.baseUrl;
  public destinationUrl:string = 'http://localhost:3000/destination';
  public benificiaryUrl :string = 'http://localhost:3000/benificiary';
  public markupUrl:string = 'http://localhost:3000/markup';
  public apiUrl:string = 'http://localhost:3000/serviceFee';
  public agentListByfunctionality : string = `${this.apiUrll}/user/getAgentsListByFunctionality`;

  httpOptions = {
  headers:new HttpHeaders({'content-type':'application/json'})
  }

  constructor(private _http:HttpClient) { }

  private extractData(res:Response):Array<any> | {} {
    const body= res;
    return body || {};
  }
  public getDestination():Observable<any>{
    return this._http.get(this.destinationUrl,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getBenList():Observable<any>{
    return this._http.get(this.benificiaryUrl,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
 public getMarkup():Observable<any>{
   return this._http.get(this.markupUrl,this.httpOptions).pipe(
     map(this.extractData)
   )
 }
 public postMarkup(markupRecord:any):Observable<any>{
   return this._http.post(this.apiUrl,markupRecord,this.httpOptions).pipe(
     map(this.extractData)
   )
 }
 public getMarkupRecord():Observable<any>{
   return this._http.get(this.apiUrl,this.httpOptions).pipe(
     map(this.extractData)
   )
 }
 public putMarkupRecord(markupRecord:any,id:number):Observable<any>{
   const url=`${this.apiUrl}/${id}`
   return this._http.put(url,markupRecord,this.httpOptions).pipe(
     map(this.extractData)
   )
 }

 public agentfunctionality(userRecord:string):Observable<any>{
   return this._http.post(this.agentListByfunctionality,this.httpOptions).pipe(
     map(this.extractData)
   )

 }


}
