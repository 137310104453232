import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuditTrailService {
  apiUrl=environment.baseUrl
  public saveAuditTrailDetailsUrl: string = `${this.apiUrl}/access/saveAuditTrailDetails`;
public getAuditTrialListUrl: string = `${this.apiUrl}/access/getAuditTrialList`;
public getAuditTrialListBetWeenDatesUrl: string = `${this.apiUrl}/access/getAuditTrialListBetWeenDates`;
public getAuditTrialListBetWeenDatesAndUserNameUrl: string = `${this.apiUrl}/access/getAuditTrialListBetWeenDatesAndUserName`;
public getAuditTrialListByUserNameurl: string = `${this.apiUrl}/access/getAuditTrialListByUserName`;
public getAuditTrialListByAgentIdUrl: string = `${this.apiUrl}/access/getAuditTrialListByAgentId`;
public getAuditTrialListBetWeenDatesAndAgentIdUrl: string = `${this.apiUrl}/access/getAuditTrialListBetWeenDatesAndAgentId`;
public getAuditTrialListBetWeenDatesAndUserNameAndAgentIdUrl: string = `${this.apiUrl}/access/getAuditTrialListBetWeenDatesAndUserNameAndAgentId`;

httpOptions = {
  headers: new HttpHeaders({ 'content-type': 'application/json' })
  //.set('Authorization', localStorage.getItem("datas"))

};

constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute) { }

private extractData(res: Response): Array<any> | {} {
  const body = res;
  console.log('res ', res)
  return body || {};
}



public saveAuditTrailDetails(record: any,pId:string): Observable<any> {
  console.log('saveAuditTrailDetailsUrl....',pId);
  return this._http.post(this.saveAuditTrailDetailsUrl + '?pId=' + pId, record, this.httpOptions).pipe(
    map(this.extractData)
  );
}


public getAuditTrialList(pId: string): Observable<any> {
  console.log("getAuditTrialListUrl  list.." + pId);
  return this._http.get(`${this.getAuditTrialListUrl}` + '?pId=' + pId, this.httpOptions).pipe(
    map(this.extractData)
  );
}


public getAuditTrialListBetWeenDates(date1:any,date2:any,pId: string): Observable<any> {
  console.log("getAuditTrialListBetWeenDatesUrl  list.." + pId);
  return this._http.get(`${this.getAuditTrialListBetWeenDatesUrl}` + '?pId=' + pId+'&date1='+date1+'&date2='+date2, this.httpOptions).pipe(
    map(this.extractData)
  );
}

public getAuditTrialListBetWeenDatesAndUserName(date1:any,date2:any,userName:any,pId: string): Observable<any> {
  console.log("getAuditTrialListBetWeenDatesAndUserNameUrl  list.." + pId);
  return this._http.get(`${this.getAuditTrialListBetWeenDatesAndUserNameUrl}` + '?pId=' + pId+'&date1='+date1+'&date2='+date2+'&userName='+userName, this.httpOptions).pipe(
    map(this.extractData)
  );
}

//getAuditTrialListByUserNameurl
public getAuditTrialListByUserName(userName:any,pId: string): Observable<any> {
console.log("getAuditTrialListByUserNameurl  list.." + pId);
return this._http.get(`${this.getAuditTrialListByUserNameurl}` + '?pId=' + pId+'&userName='+userName, this.httpOptions).pipe(
  map(this.extractData)
);
}
//getAuditTrialListByAgentIdUrl
public getAuditTrialListByAgentId(agentId:any,pId: string): Observable<any> {
console.log("getAuditTrialListByAgentIdUrl  list.." + pId);
return this._http.get(`${this.getAuditTrialListByAgentIdUrl}` + '?pId=' + pId+'&agentId='+agentId, this.httpOptions).pipe(
  map(this.extractData)
);
}
//getAuditTrialListBetWeenDatesAndAgentIdUrl
public getAuditTrialListBetWeenDatesAndAgentId(date1:any,date2:any,agentId:any,pId: string): Observable<any> {
console.log("getAuditTrialListBetWeenDatesAndAgentIdUrl  list.." + pId);
return this._http.get(`${this.getAuditTrialListBetWeenDatesAndAgentIdUrl}` + '?pId=' + pId+'&date1='+date1+'&date2='+date2+'&agentId='+agentId, this.httpOptions).pipe(
  map(this.extractData)
);
}
//getAuditTrialListBetWeenDatesAndUserNameAndAgentIdUrl
public getAuditTrialListBetWeenDatesAndUserNameAndAgentId(date1:any,date2:any,userName:any,agentId:any,pId: string): Observable<any> {
console.log("getAuditTrialListBetWeenDatesAndUserNameAndAgentIdUrl  list.." + pId);
return this._http.get(`${this.getAuditTrialListBetWeenDatesAndUserNameAndAgentIdUrl}` + '?pId=' + pId+'&date1='+date1+'&date2='+date2+'&userName='+userName+'&agentId='+agentId, this.httpOptions).pipe(
  map(this.extractData)
);
}

}
