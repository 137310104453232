import { HttpClient, HttpErrorResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BranchService } from './service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(
    private _router:Router,
    private injector:Injector,
    private _http:HttpClient) { }
  intercept(req,next): Observable<HttpEvent<any>> {
    let authService=this.injector.get(BranchService)
    let tokenizedReq=req.clone({
    setHeaders:{
          Authorization:`Bearer ${authService.getToken()}`
    }
})

return next.handle(tokenizedReq).pipe(
  catchError((error:HttpErrorResponse) => {
     
   if (error instanceof HttpErrorResponse) {

if(error.status===0){
  return next.handle(req.clone({
  setHeaders:{
        Authorization:`Bearer ${authService.getToken()}`
  }
}))
}


//  alert()
// if(error.status===0){
//
//   localStorage.clear();
//   this._router.navigate(['/signin'])
//
// }
//
 }
 return throwError(error);

})
)
}
}
