import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map,tap } from 'rxjs/operators';
import {Observable} from 'rxjs';
import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
apiUrl=environment.baseUrl;

public baseapi1 :string =`${this.apiUrl}/utility/getSates`;
public apiUrl13: string = `${this.apiUrl}/utility/getRelationShipList`;
 httpOptions = {
       headers : new HttpHeaders ({})
 };
  constructor(private _http:HttpClient) { }
  private  extractData( res: Response ) : Array <any> | {} {
    const body = res;
    console.log('res ',res)
    return body || {} ;
   }

   public getCountryStates(countryIso: any,pId : string): Observable <any>{
    // alert(''+countryIso)
     console.log("pId..in  country states...!"+pId+"......."+countryIso);
     return this._http.get(`${this.baseapi1}`+'?pId='+pId+'&countryIso='+countryIso,this.httpOptions).pipe(
     map(this.extractData)
   );
   }
   public getRelationShipList(pId: string): Observable<any> {
     console.log("working getusers Relation Ship LIST..." + pId);
     return this._http.get(`${this.apiUrl13}` + '?pId=' + pId, this.httpOptions).pipe(
       map(this.extractData)
     );
   }



}
