import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ExchangeRatesService {
apiUrl=environment.baseUrl;
  public exchangeRateListUrl : string=`${this.apiUrl}/exchRate/getAllExchangeRates`;
  public destExchangeRateListUrl : string=`${this.apiUrl}/exchRate/getAllExchangeRatesByDestAgentId`;
  public srcExchangeRateListUrl : string=`${this.apiUrl}/exchRate/getAllExchangeRatesBySrcAgentId`;
public getApiMarkupByApiNameUrl:string=`${this.apiUrl}/exchRate/getByApiName`;
  public updateUrl:string=`${this.apiUrl}/exchRate/updateExchangeRateById`;
  public getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiNameUrl:string=`${this.apiUrl}/exchRate/getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiName`
public getExchangeRatesListByDestAgentIdAndRateFlagUrl:string=`${this.apiUrl}/exchRate/getExchangeRatesListByDestAgentIdAndRateFlag`;
public getExchangeRatesListBySrcAgentIdAndRateFlagUrl:string=`${this.apiUrl}/exchRate/getExchangeRatesListBySrcAgentIdAndRateFlag`;
public getExchangeDetailsByRateFlagAndApiNameUrl:string=`${this.apiUrl}/exchRate/getExchangeDetailsByRateFlagAndName`;
public getApiMarkupListUrl:string=`${this.apiUrl}/exchRate/getApiMarkupList`;
public updateApiMarkupTypeByNameUrl:string=`${this.apiUrl}/exchRate/updateApiMarkupTypeByName`;
    public updateApiMarkupByApiNameUrl:string=`${this.apiUrl}/exchRate/updateApiMarkupByApiName`;
  httpOptions1 = {
    headers : new HttpHeaders ({'content-type' : 'application/json','Authorization':'Bearer ' +localStorage.getItem("datas")})
  }
  httpOptions = {
    headers : new HttpHeaders ({})

  };

  constructor(private _http:HttpClient) { }
  private extractData( res :Response ) : Array <any> | {} {
    const body = res;
    console.log('res',res);
    return body || {};
  }


  public updateApiMarkupTypeByName(apiName:any,markup:number,pId : string) : Observable <any> {
    console.log("working updateApiMarkupTypeByName="+pId);
    return this._http.put(`${this.updateApiMarkupTypeByNameUrl}`+'?pId='+pId+'&apiName='+apiName+'&markupType='+markup,this.httpOptions).pipe(
    map(this.extractData)
    );

  }
  //updateApiMarkupByApiName
  public updateApiMarkupByApiName(apiName:any,markup:number,pId : string) : Observable <any> {
    console.log("working getApiMarkupByApiName="+pId);
    return this._http.get(`${this.updateApiMarkupByApiNameUrl}`+'?pId='+pId+'&apiName='+apiName+'&markup='+markup,this.httpOptions1).pipe(
    map(this.extractData)
    );

  }

  //getExchangeDetailsByRateFlagUrl
  public getExchangeDetailsByRateFlagAndApiName(rateFlag:String,apiName:string,pId : string) : Observable <any> {
    console.log("working getExchangeDetailsByRateFlagAndApiName="+pId);
    return this._http.get(`${this.getExchangeDetailsByRateFlagAndApiNameUrl}`+'?pId='+pId+'&rateFlag='+rateFlag+'&apiName='+apiName,this.httpOptions).pipe(
    map(this.extractData)
    );
  }


  //getExchangeRatesListBySrcAgentIdAndRateFlagUrl
  public getExchangeRatesListBySrcAgentIdAndRateFlag(agentId:any,rateFlag:any,pId : string) : Observable <any> {
    console.log("working getAllSrcExchangeRates="+pId);
    return this._http.get(`${this.getExchangeRatesListBySrcAgentIdAndRateFlagUrl}`+'?pId='+pId+'&srcAgentId='+agentId+'&rateFlag='+rateFlag,this.httpOptions).pipe(
    map(this.extractData)
    );
  }

  //getExchangeRatesListByDestAgentIdAndRateFlagUrl
  public getExchangeRatesListByDestAgentIdAndRateFlag(agentId:any,rateFlag:any,pId : string) : Observable <any> {
    console.log("working getExchangeRatesListByDestAgentIdAndRateFlagUrl="+pId);
    return this._http.get(`${this.getExchangeRatesListByDestAgentIdAndRateFlagUrl}`+'?pId='+pId+'&destAgentId='+agentId+'&rateFlag='+rateFlag,this.httpOptions).pipe(
    map(this.extractData)
    );
  }


  public getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiName(srcAgentId:any,destAgentId:any,rateFlag:any,apiName:string,pId : string) : Observable <any> {
    console.log("working getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiName="+pId);
    return this._http.get(`${this.getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiNameUrl}`+'?pId='+pId+'&srcAgentId='+srcAgentId+'&destAgentId='+destAgentId+'&rateFlag='+rateFlag+'&apiName='+apiName,this.httpOptions).pipe(
    map(this.extractData)
    );
  }



  //getApiMarkupByApiName
  public getApiMarkupByApiName(apiName:any,pId : string) : Observable <any> {
    console.log("working getApiMarkupByApiName="+apiName);
    return this._http.get(`${this.getApiMarkupByApiNameUrl}`+'?pId='+pId+'&apiName='+apiName,this.httpOptions).pipe(
    map(this.extractData)
    );
  }


  public getAllExchangeRates(pId : string) : Observable <any> {
    console.log("working getAllExchangeRates="+pId);
    return this._http.get(`${this.exchangeRateListUrl}`+'?pId='+pId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }

  public getAllDestExchangeRates(agentId:any,pId : string) : Observable <any> {
    console.log("working getAllDestExchangeRates="+pId);
    return this._http.get(`${this.destExchangeRateListUrl}`+'?pId='+pId+'&destAgentId='+agentId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }

  public getAllSrcExchangeRates(agentId:any,pId : string) : Observable <any> {
    console.log("working getAllSrcExchangeRates="+pId);
    return this._http.get(`${this.srcExchangeRateListUrl}`+'?pId='+pId+'&srcAgentId='+agentId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }

  public updateExchangeRateById(exchangeRecord:any,pId:string,exchRateId:number){
    console.log("working updateExchangeRateById="+pId+"..exchRateId="+JSON.stringify(exchangeRecord));
    return this._http.post(`${this.updateUrl}`+'?pId='+pId+'&exchRateId='+exchRateId,exchangeRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  //getApiMarkupList
  public getApiMarkupList(pId : string) : Observable <any> {
    console.log("working getApiMarkupList="+pId);
    return this._http.get(`${this.getApiMarkupListUrl}`+'?pId='+pId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }
}
