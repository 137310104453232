import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { Router, ActivatedRoute } from '@angular/router';
import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentwithbranchService {
  getCountryList(arg0: string) {
    throw new Error('Method not implemented.');
  }
  apiUrl=environment.baseUrl;
  public baseUrl: string = `${this.apiUrl}/user/createAgentWithExistingBranch`;
  public branchUrl: string = `${this.apiUrl}/branch/createBranch`;
public apiUrl9: string = `${this.apiUrl}/keyclock/getAllCountrys`;
  public apiUrl2: string = `${this.apiUrl}/keyclock/getClientCountrys`;
  public apiUrl3: string = `${this.apiUrl}/keyclock/getTimeZonesByCountryIso`;
  public apiUrl4: string = `${this.apiUrl}/keyclock/getClientBenePayTypes`;
  public apiUrl5: string = `${this.apiUrl}/keyclock/getClientCollectionTypes`;
  public apiUrl6: string = `${this.apiUrl}/branch/getBranchesList`;

  public apiUrl7: string = `${this.apiUrl}/branch/getBranchInfo`;
  public insertUrl1: string = `${this.apiUrl}/user/createAgentWithExistingBranch`;
  public insertUrl2: string = `${this.apiUrl}/user/agentCreationWithNewBranch`;
  public agentListUr: string = `${this.apiUrl}/user/getAgentsList`;
  public apiUrl10: string = `${this.apiUrl}/utility/getReasonForTransfer`;
  public apiUrl11: string = `${this.apiUrl}/user/getBackendDestAgentsByAgentId`;
  public apiUrl12: string = `${this.apiUrl}/utility/getSourceOfFundList`;
  public apiUrl13: string = `${this.apiUrl}/utility/getRelationShipList`;
  public agentUrl: string = `${this.apiUrl}/user/getAgentById`;
  // public editUrl:string= 'http://localhost:3000/data';
public customerListApi:string = `${this.apiUrl}/customer/getCustomersList`;
//getAgentById
public getAgentByIdUrl: string = `${this.apiUrl}/user/getAgentById`;

  // public insertUrl23 : string = 'http://localhost:3000/data';

  // httpOptions = {
  //   headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))
  //
  // };

  httpOptions = {
    headers : new HttpHeaders ({'content-type' : 'application/json',
    'Authorization': 'Bearer ' +localStorage.getItem("datas")})
  };
  httpOptions1 = {
    headers : new HttpHeaders ({})
  };

  constructor(private _http: HttpClient, private router: Router,private _httpp:HttpClient, private _route: ActivatedRoute,handler: HttpBackend) { 


    this._httpp = new HttpClient(handler);
  }



  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res ', res)
    return body || {};
  }

  public getCustomerList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.customerListApi}`+'?pId='+pId,this.httpOptions1).pipe(
      map(this.extractData)
    )
  }
  public createEbranch(branchRecord: any): Observable<any> {


    return this._http.post(this.baseUrl, branchRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getClientCountrys(token: any, pId: string): Observable<any> {
    console.log("working getusers client countries..." + pId);
    return this._httpp.get(`${this.apiUrl2}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getTimeZone(countryIso: any, pId: string): Observable<any> {
    console.log("working get Timezonelist.." + countryIso);
    return this._httpp.get(`${this.apiUrl3}` + '?countryIso=' + countryIso+'&pId='+pId, this.httpOptions1).pipe(
      map(this.extractData)
    );
  }

  public getBenPayment(token: any, pId: string): Observable<any> {

    console.log("working getBenificiary", token);
    return this._http.get(`${this.apiUrl4}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );

  }
  public getCollection(token: any, pId: string): Observable<any> {
    console.log("collection", token);
    return this._http.get(`${this.apiUrl5}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );

  }



  public getBranch(pId: string): Observable<any> {
    console.log('branchlist working');
    return this._http.get(`${this.apiUrl6}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }


  public getAgentList(pId: string): Observable<any> {
    console.log('branchlist working');
    return this._http.get(`${this.agentListUr}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  // public getAgentList1(): Observable<any> {
  //   console.log('branchlist working');
  //   return this._http.get(`${this.editUrl}`, this.httpOptions).pipe(
  //     map(this.extractData)
  //   );
  // }

  // public getAgentById(pId: string): Observable<any> {
  //   console.log('agentById working');
  //   return this._http.get(`${this.agentUrl}` + '?pId=' + pId, this.httpOptions).pipe(
  //     map(this.extractData)
  //   );
  // }

//getAgentByIdUrl
public getAgentById(pId: string,Id:any): Observable<any> {
  console.log('agentById working');
  return this._http.get(`${this.getAgentByIdUrl}` + '?pId=' + pId+'&Id='+Id).pipe(
    map(this.extractData)
  );
}




  public getBranchIn(branchId: any, pId: string): Observable<any> {
    console.log('branchinfo working', branchId);
    return this._http.get(`${this.apiUrl7}/${branchId}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public saveAgentWithExistingBranch(Record: any, pId: string): Observable<any> {
    console.log('agentwithnew working', Record)
    return this._http.post(this.insertUrl1 + '?pId=' + pId, Record, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public saveAgentWithNewBranch(recordList: any, pId: string): Observable<any> {
    return this._http.post(this.insertUrl2 + '?pId=' + pId, recordList, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAgentWithExistingBranchListById(userId:any,pId:string):Observable<any>{
    return this._http.get(`${this.agentUrl}`+'?pId=' + pId+'&Id='+userId).pipe(
      map(this.extractData)
    )
  }

  public getAllCountrys(pId: string): Observable<any> {
    console.log("working getusers client countries..." + pId);
    return this._http.get(`${this.apiUrl9}` + '?pId=' + pId, this.httpOptions1).pipe(
      map(this.extractData)
    );
  }

    public getReasonForTransferList(pId: string): Observable<any> {
      console.log("working getusers reason for transfer..." + pId);
      return this._http.get(`${this.apiUrl10}` + '?pId=' + pId, this.httpOptions1).pipe(
        map(this.extractData)
      );
    }

    public getBackendTransactionList(pId: string,ag: string): Observable<any> {
      console.log("working getusers backend agentsList..." + pId);
      return this._http.get(`${this.apiUrl11}` + '?pId=' + pId + '&agent=' +ag, this.httpOptions).pipe(
        map(this.extractData)
      );
    }

    public getSourceOfFundList(pId: string): Observable<any> {
      console.log("working getusers SOURCE OF FUND LIST..." + pId);
      return this._http.get(`${this.apiUrl12}` + '?pId=' + pId, this.httpOptions1).pipe(
        map(this.extractData)
      );
    }
    public getRelationShipList(pId: string): Observable<any> {
      console.log("working getusers Relation Ship LIST..." + pId);
      return this._http.get(`${this.apiUrl13}` + '?pId=' + pId, this.httpOptions1).pipe(
        map(this.extractData)
      );
    }




}
